var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"type":_vm.$store.state.causelistStore.causelists}},_vm._l((_vm.$store.state.causelistStore.causelists),function(causelist){return _c('div',[_c('b-card',{staticClass:"m-0 p-0 rounded-0",style:(_vm.isDark
          ? 'background-color: #343d55; border-color: #343d55'
          : 'background-color: #f3f2f7; border-color: #e6e9eb'),attrs:{"no-body":"","header":causelist.date,"header-text-variant":"black","header-raduis":"0","header-class":"m-0 p-1 rounded-0"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-col',{staticClass:"col-12 text-start"},[_c('b-row',{staticClass:"justify-content-between"},[_c('b-col',[_c('h4',{staticClass:"text-black"},[_vm._v(_vm._s(causelist.date)),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" ("+_vm._s(causelist.data.length)+")")])]),_c('p',{staticClass:"font-weight-bold p-0 m-0"})]),_c('div',[_c('export-hearing-by-date',{attrs:{"date":causelist.date,"userId":_vm.id}})],1)],1)],1)]},proxy:true}],null,true)},[_c('b-card',{staticClass:"p-0 m-0 rounded-0",attrs:{"no-body":""}},[_c('b-table',{ref:"refInvoiceListTable",refInFor:true,staticClass:"position-relative",attrs:{"items":causelist.data,"fields":_vm.fields,"responsive":"","primary-key":causelist._id,"show-empty":"","empty-text":"No records found"},scopedSlots:_vm._u([{key:"cell(title)",fn:function(data){return [_c('h6',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mb-1 text-primary cursor-pointer",attrs:{"title":"View Matter"},on:{"click":function($event){return _vm.$router.push({
                  name: 'user-matter-preview',
                  params: { id: data.item._id }
                })}}},[_vm._v(" "+_vm._s(data.item.title)+" ")])]}},{key:"cell(unique_id)",fn:function(data){return [_c('b-col',[_c('div',[(data.item.unique_id)?_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getUniqueId(data.item))+" ")]):_vm._e()]),(Object.keys(data.item).includes('cause_name'))?_c('div',[(data.item.cause_name)?_c('small',[_vm._v(" ["+_vm._s(data.item.cause_name)+"] ")]):_vm._e()]):_vm._e()])]}},{key:"cell(court_id)",fn:function(data){return [_c('b-col',[(Object.keys(data.item).includes('ctitle'))?_c('div',[_c('small',{staticClass:"text-secondary font-italic"},[_vm._v(_vm._s(data.item.ctitle))])]):_vm._e(),(
                  Object.keys(data.item).includes('serial_number') ||
                  Object.keys(data.item).includes('court_number')
                )?_c('div',[(
                    data.item.court_number > 0 || data.item.serial_number > 0
                  )?_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" Listed: ")]):_vm._e(),(Object.keys(data.item).includes('serial_number'))?_c('small',[(data.item.serial_number > 0)?_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" [ Sr No. "+_vm._s(data.item.serial_number)+" ] ")]):_vm._e()]):_vm._e(),(Object.keys(data.item).includes('court_number'))?_c('small',[(
                        data.item.court_number > 0 || data.item.court_number === -10
                      )?_c('small',{staticClass:"text-primary font-weight-bolder text-uppercase"},[_vm._v(" - [ Court No. "+_vm._s(data.item.court_number === -10 ? 'C.J.' : data.item.court_number)+" ] ")]):_vm._e()]):_vm._e()]):_vm._e(),(Object.keys(data.item).includes('judges'))?_c('div',[(data.item.judges)?_c('div',[(data.item.judges.length)?_c('small',{staticClass:"text-black font-weight-bold"},[_vm._v(_vm._s(data.item.judges.length > 1 ? 'JUSTICES' : 'JUSTICE')+" "+_vm._s(data.item.judges.join(","))+" ")]):_vm._e()]):_vm._e()]):_vm._e()])]}},{key:"cell(actions)",fn:function(data){return [_c('b-row',{staticClass:"text-nowrap"},[_c('b-col',{staticClass:"align-self-center col-8",attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-right"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"badge-round cursor-pointer mr-1",attrs:{"variant":"primary","pill":"","title":"View Matter"},on:{"click":function($event){return _vm.$router.push({
                        name: 'user-matter-preview',
                        params: { id: data.item._id }
                      })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"id":("matter-" + (data.item._id) + "-preview-icon"),"icon":"EyeIcon","size":"16"}}),_c('span',[_vm._v("View")])],1)],1),(Object.keys(data.item).includes('last_order_url'))?_c('div',[(data.item.last_order_url)?_c('b-badge',{staticClass:"badge-round mt-2",attrs:{"variant":"light","pill":"","href":data.item.last_order_url.split('.pdf')[0] + '.pdf',"target":"_blank"}},[_c('feather-icon',{staticClass:"mr-25 cursor-pointer text-primary",attrs:{"id":("order-" + (data.item._id) + "-preview-icon"),"icon":"Link2Icon","size":"16"}}),_c('span',{staticClass:"text-primary"},[_vm._v("Last Order")])],1):_vm._e()],1):_vm._e()])],1)]}}],null,true)})],1)],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }