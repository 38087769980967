<template>
  <b-card no-body class="border-primary">
    <b-card-header
      class="d-flex justify-content-between align-items-center pt-75 pb-25"
    >
      <h5 class="mb-0">Current Plan</h5>
      <b-badge variant="light-primary"> Basic </b-badge>
    </b-card-header>

    <b-card-body>
      <ul class="list-unstyled my-1">
        <li>
          <b-row>
            <b-col cols="6">
              <span>Paid On</span>
            </b-col>
            <b-col cols="6">
              <span>24, Aug 2022</span>
            </b-col>
          </b-row>
        </li>
        <li>
          <b-row>
            <b-col cols="6">
              <span>Expire On</span>
            </b-col>
            <b-col cols="6">
              <span>24, Aug 2023</span>
            </b-col>
          </b-row>
        </li>
        <li class="my-25">
          <b-row>
            <b-col cols="6">
              <span>Days Left</span>
            </b-col>
            <b-col cols="6">
              <span>123</span>
            </b-col>
          </b-row>
        </li>
        <li>
          <b-row>
            <b-col cols="6">
              <span>Plan Cycle</span>
            </b-col>
            <b-col cols="6">
              <span>Yearly</span>
            </b-col>
          </b-row>
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BButton,
  BRow,
  BCol
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BRow,
    BCol
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: this.userId
    }
  }
}
</script>

<style></style>
