<template>
  <!-- BODY -->
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <!-- Form -->
    <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
      <b-list-group-item class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="text-primary">{{ tribunal.court_name }}</h5>
        </div>
        <b-card-text>
          <validation-provider
            #default="validationContext"
            name="Filter Names"
            rules="required"
          >
            <b-form-group label="Filter Names" label-for="filter_by_names">
              <b-form-tags
                v-model="formData.filter_by_names"
                placeholder="Add Lawyer Names"
                size="md"
                input-id="filter_by_names"
              />
              <b-form-invalid-feedback :state="false" class="text-info">
                Type and press enter to push new name.
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-row class="justify-content-end pr-1">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-2"
              size="sm"
              type="submit"
              :disabled="!formData.filter_by_names"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-danger"
              @click="deleteTribunal"
            >
              Delete
            </b-button>
          </b-row>
        </b-card-text>
      </b-list-group-item>
      <!-- Advocate Name (For Tribunal Court) -->
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCardText,
  BInputGroup,
  BFormTags,
  BInputGroupAppend,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTags,
    BCardText,
    vSelect,
    BInputGroup,
    BRow,
    BCol,
    BInputGroupAppend,
    BListGroup,
    BListGroupItem,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  props: {
    tribunalData: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      required
    }
  },
  setup(props, context) {
    var tribunal = props.tribunalData
    const blankUserData = {
      user_id: props.data._id,
      court_id: tribunal.court_id,
      filter_by_key: tribunal.filter_by_key,
      filter_by_names: tribunal.filter_by_names
    }

    const formData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      formData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store
        .dispatch('userStore/updateTribunal', {
          payload: formData.value,
          id: tribunal._id
        })
        .then((response) => {
          if (response != null) {
            context.emit('refetch-data')
          }
        })
    }

    function deleteTribunal() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You really want to delete this tribunal',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          store
            .dispatch('userStore/deleteTribunal', {
              id: tribunal._id
            })
            .then((response) => {
              context.emit('refetch-data')
            })
        }
      })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)

    return {
      formData,
      onSubmit,
      refFormObserver,
      getValidationState,
      tribunal,
      deleteTribunal,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
