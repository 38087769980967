<template>
  <div v-if="data.ia !== null">
    <section v-if="data.ia.length">
      <h3 class="pb-1">IA Details</h3>
      <b-row>
        <b-col class="col-12">
          <app-collapse :type="'border'">
            <app-collapse-item title="View IA Details" class="text-info">
              <b-table
                stacked
                responsive="sm"
                :items="data.ia"
              /> </app-collapse-item
          ></app-collapse>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    AppCollapse,
    AppCollapseItem
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  }
}
</script>
