<template>
  <div>
    <div v-b-modal="'modal-' + item._id">
      <feather-icon
        :id="`matter-${item._id}-preview-icon`"
        icon="EditIcon"
        size="16"
      />
      Edit
    </div>

    <!-- select 2 demo -->
    <b-modal
      :id="'modal-' + item._id"
      title="Update Inquiry"
      no-close-on-backdrop
      centered
      ok-title="submit"
      :hide-footer="true"
      cancel-variant="outline-secondary"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Message -->
          <validation-provider
            #default="validationContext"
            name="Comment"
            rules="required"
          >
            <b-form-group label="Comment" label-for="comment">
              <b-form-textarea
                id="comment"
                v-model="blankData.comment"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                rows="3"
                placeholder="Comment..."
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group label="Status" label-for="status">
              <v-select
                id="status"
                v-model="blankData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusFilter"
                :reduce="(val) => val"
                class="invoice-filter-select"
                placeholder="Select Status"
                :state="getValidationState(validationContext)"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$bvModal.hide('modal-' + item._id)"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BCardText,
  BFormTextarea,
  BFormGroup,
  BTabs,
  BTab,
  BOverlay,
  BSpinner,
  BFormInvalidFeedback
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    BCardText,
    BFormInvalidFeedback,
    BFormGroup,
    BTab,
    BTabs,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
    Ripple
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // Message
      message: null,
      item: this.data,

      required
    }
  },
  methods: {
    onSubmit() {
      console.log(this.blankData)
      store
        .dispatch('ticketStore/updateInquiry', { payload: this.blankData })
        .then((response) => {
          console.log('FDF', response)
          if (response) {
            this.$bvModal.hide('modal-' + this.item._id)
            this.$emit('refetch-data')
            this.$swal({
              icon: 'success',
              title: 'Inquiry Updated',
              text: 'Inquiry has been updated successfully!',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          } else {
            this.$swal({
              icon: 'error',
              title: 'Failed to Submit',
              text: 'Please try again!',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          }
        })
    }
  },
  setup(props, context) {
    const blankData = {
      _id: props.data._id,
      comment: '',
      status: props.data.status
    }

    if (Object.keys(props.data).includes('comment')) {
      blankData['comment'] = props.data.comment
    }

    var statusFilter = ['PENDING', 'RESOLVED', 'FOLLOWUP']

    const ticketData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      ticketData.value = JSON.parse(JSON.stringify(blankData))
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData)

    return {
      blankData,
      statusFilter,
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
