<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">My Munshi</h2>
      </b-link> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Verify OTP! ✅ </b-card-title>
          <b-card-text class="mb-2">
            OTP has been sent to your mobile +91xxxxxxxx{{$store.state.resetPasswordStore.mobile.toString().slice(-2)}}, enter below to
            verify your account.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group label="OTP" label-for="forgot-password-email">
                <validation-provider
                  #default="{ errors }"
                  name="OTP"
                  rules="required"
                >
                  <b-form-input
                    id="forgot-password-otp"
                    v-model="otp"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-otp"
                    placeholder="Enter OTP"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-overlay
                :show="$store.state.resetPasswordStore.statusLoading"
                rounded="sm"
                variant="transparent"
                opacity="0.5"
                blur="2px"
              >
                <b-button type="submit" variant="primary" block>
                  Send OTP
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link @click="$router.go(-1)">
              <feather-icon icon="ChevronLeftIcon" /> Change Account?
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BOverlay
  },
  data() {
    return {
      otp: '',
      sideImg: require('@/assets/images/logo/logo_light.png'),
      // validation
      required
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo_light.png')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.otp.length === 6) {
            let payload = {
              otp: this.otp
            }
            this.$store
              .dispatch('resetPasswordStore/verifyOTP', { ctx: this, payload })
              .then((status) => {
                if (status) {
                  this.$router.replace({
                    name: 'auth-forgot-password'
                  })
                }
              })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Enter 6 digit OTP.',
                icon: 'EditIcon',
                variant: 'error'
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
