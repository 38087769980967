<template>
  <b-row v-if="$store.state.settingStore.planUsers.length">
    <b-col lg="3" sm="6">
      <statistic-card-horizontal
        icon="UsersIcon"
        :statistic="getTotalUsers($store.state.settingStore.planUsers)"
        color="primary"
        statistic-title="Total Users"
      />
    </b-col>
    <b-col lg="3" sm="6">
      <statistic-card-horizontal
        icon="UserPlusIcon"
        :statistic="getPaidUsers($store.state.settingStore.planUsers)"
        color="success"
        statistic-title="Paid Users"
      />
    </b-col>
    <b-col lg="3" sm="6">
      <statistic-card-horizontal
        icon="UserMinusIcon"
        color="info"
        :statistic="getTrialUsers($store.state.settingStore.planUsers)"
        statistic-title="Trial Users"
      />
    </b-col>
    <b-col lg="3" sm="6">
      <statistic-card-horizontal
        icon="UserXIcon"
        color="warning"
        :statistic="getExpiredUsers($store.state.settingStore.planUsers)"
        statistic-title="Expired Users"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BRow,
  BCol,
  BDropdownItem,
  BCardBody
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCardBody,
    StatisticCardHorizontal
  },
  data() {
    return {}
  },
  created() {
    this.$store.dispatch('settingStore/fetchPlanUsers')
  },
  methods: {
    getTotalUsers(planUsers) {
      var counts = 0
      for (var i in planUsers) {
        counts = counts + planUsers[i].count
      }
      return counts
    },
    getPaidUsers(planUsers) {
      var counts = 0
      for (var i in planUsers) {
        if (!planUsers[i].is_free) {
          counts = counts + planUsers[i].count
        }
      }
      return counts
    },
    getTrialUsers(planUsers) {
      var counts = 0
      for (var i in planUsers) {
        if (planUsers[i].is_free) {
          counts = counts + planUsers[i].count
        }
      }
      return counts
    },
    getExpiredUsers(planUsers) {
      var counts = 0
      if (planUsers) {
        counts = planUsers[0].total_expired
      }
      return counts
    }
  }
}
</script>
