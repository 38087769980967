<template>
  <section>
    <h3 class="pb-1">Case Status</h3>
    <b-card no-body class="py-75">
      <!-- First Hearing Date -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Filing Date</h6>
        </b-col>
        <b-col class="col-7">
          <p class="m-0" v-if="Object.keys(data).includes('filing_date')">
            {{ data.filing_date }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Next Hearing Date -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Last Hearing</h6>
        </b-col>
        <b-col class="col-7">
          <div v-if="Object.keys(data).includes('case_proceeding')">
            <div v-if="data.case_proceeding.length">
              <p
                class="m-0 text-primary"
                v-if="
                  Object.keys(data.case_proceeding[0]).includes('hearing_at')
                "
              >
                {{ data.case_proceeding[0].hearing_at }}
              </p>
              <p class="m-0" v-else>--</p>
            </div>
            <p class="m-0" v-else>--</p>
          </div>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Next Hearing Date -->
      <!-- <b-row class="px-1">
          <b-col class="col-5">
            <h6 class="m-0">Next Hearing</h6>
          </b-col>
          <b-col class="col-7">
            <div v-if="Object.keys(data).includes('hearing_dates')">
              <p
                class="m-0 text-success font-weight-bold"
                v-if="data.hearing_dates !== null"
              >
                {{ getNextDate({ dates: data.hearing_dates }) }}
              </p>
              <p class="m-0" v-else>--</p>
            </div>
            <p class="m-0" v-else>--</p>
          </b-col>
        </b-row>
        <hr style="margin: 5px 0" /> -->
      <!-- Location -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Location</h6>
        </b-col>
        <b-col class="col-7">
          <p class="m-0" v-if="Object.keys(data).includes('location')">
            {{ data.location }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Status of Case -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Status/Stage</h6>
        </b-col>
        <b-col class="col-7">
          <p class="m-0" v-if="Object.keys(data).includes('case_status')">
            {{ data.case_status }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Disposal Nature -->
      <b-row class="px-1" v-if="Object.keys(data).includes('disposal_nature')">
        <b-col class="col-5">
          <h6 class="m-0">Disposal Nature</h6>
        </b-col>
        <b-col class="col-7">
          <p class="m-0">
            {{ data.disposal_nature }}
          </p>
        </b-col>
      </b-row>
      <hr
        style="margin: 5px 0"
        v-if="Object.keys(data).includes('disposal_nature')"
      />
      <!-- Disposal Date -->
      <b-row class="px-1" v-if="Object.keys(data).includes('disposal_date')">
        <b-col class="col-5">
          <h6 class="m-0">Disposal Date</h6>
        </b-col>
        <b-col class="col-7">
          <p class="m-0">
            {{ data.disposal_date }}
          </p>
        </b-col>
      </b-row>
      <hr
        style="margin: 5px 0"
        v-if="Object.keys(data).includes('disposal_date')"
      />
      <!-- Petitioner Address -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Petitioner Address</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data).includes('petitionrs_address')"
          >
            {{ data.petitionrs_address }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Respondent Address -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Respondent Address</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data).includes('respondent_address')"
          >
            {{ data.respondent_address }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Subject -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Subject</h6>
        </b-col>
        <b-col class="col-7">
          <p class="m-0" v-if="Object.keys(data).includes('subject')">
            {{ data.subject }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Court Fee -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Court Fee</h6>
        </b-col>
        <b-col class="col-7">
          <p class="m-0" v-if="Object.keys(data).includes('court_fee')">
            {{ data.court_fee }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  },
  methods: {
    getNextDate({ dates }) {
      var date = '--'
      var dd = []
      const filteredDates = dates.filter(
        (date) => moment(date) >= moment().startOf('day')
      )
      for (let i = 0; i < filteredDates.length; i++) {
        dd.push(moment(filteredDates[i]).format('Do, MMMM YYYY'))
      }
      date = dd.join(' || ')
      return date
    }
  },
  setup() {
    return {
      formatDate
    }
  }
}
</script>
