import axiosIns from '@/libs/axios'
import defaultConfig from '@/configs/defaultConfig'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    userFormLoading: false,
    userExportLoading: false,
    userAllExportLoading: false
  },
  getters: {},
  mutations: {
    changeUserFormLoading(state, val) {
      state.userFormLoading = val
    },
    changeUserExportLoading(state, val) {
      state.userExportLoading = val
    },
    changeUserExportAllLoading(state, val) {
      state.userAllExportLoading = val
    },
    setUsersPlanToLocal(state, payload) {
      state.userCourts = payload.data
      localStorage.setItem(
        defaultConfig.usersPlanKeyName,
        JSON.stringify(payload.data)
      )
    }
  },
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getAllUsers, { params: queryParams })
          .then((response) => resolve(response.data))
          .catch((error) => reject(error))
      })
    },
    fetchUserById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${defaultConfig.getAllUsers}?filters[_id]=${id}`)
          .then((response) => {
            if (response.data.success) {
              if (response.data.data) {
                console.log(response.data.data[0])
                resolve(response.data.data[0])
              }
            }
          })
          .catch((error) => reject(error))
      })
    },
    fetchTribunalsById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`${defaultConfig.getUsersTribunals}?filters[user_id]=${id}`)
          .then((response) => {
            if (response.data.success) {
              resolve(response.data.data)
            }
          })
          .catch((error) => reject(error))
      })
    },
    deleteTribunal(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`${defaultConfig.deleteTribunal}/${id}`)
          .then((response) => {
            if (response.data.success) {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Tribunal deleted successfully!',
                  icon: 'CoffeeIcon',
                  variant: 'success'
                }
              })
              resolve(response.data)
            } else {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: '' + response.data.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },
    // Save Tribunal
    saveTribunal({ commit }, { payload }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.addTribunal, payload)
          .then((response) => {
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Tribunal saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },
    // Update Tribunal
    updateTribunal({ commit }, { payload, id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.updateTribunal + '/' + id, payload)
          .then((response) => {
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Updated successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },
    // Save User
    addUser({ commit }, { payload }) {
      commit('changeUserFormLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.saveNewUserEndPoint, payload)
          .then((response) => {
            commit('changeUserFormLoading', false)
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'User saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeUserFormLoading', false)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },
    // Update User
    updateUser({ commit }, { payload }) {
      commit('changeUserFormLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.updateUserEndPoint, payload)
          .then((response) => {
            commit('changeUserFormLoading', false)
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Updated successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeUserFormLoading', false)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Save Matters
    applyPlan({ commit }, { data }) {
      commit('changeUserFormLoading', true)
      const payload = {
        user_id: data.user_id
      }
      return new Promise((resolve, reject) => {
        axiosIns
          .post(
            defaultConfig.applyPlanEndPoint({
              plan_reference_id: data.plan_reference_id
            }),
            payload
          )
          .then((response) => {
            commit('changeUserFormLoading', false)
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Updated successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
              }
            } else {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
            }
            return null
          })
          .catch((error) => {
            commit('changeUserFormLoading', false)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },
    fetchUsersPlan({ commit }, ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getUsersPlan)
          .then((response) => {
            console.log({ UserCourtData: JSON.stringify(response.data) })
            if (response.data.success) {
              commit('setUsersPlanToLocal', response.data)
            }
          })
          .catch(console.log)
      })
    }
    //   exportAllUsers({ commit }, { ctx }) {
    //     commit('changeUserExportAllLoading', true)
    //     return new Promise((resolve, reject) => {
    //       axiosIns
    //         .post(defaultConfig.exportAllUserEndPoint)
    //         .then(async (response) => {
    //           commit('changeUserExportAllLoading', false)
    //           await downloadCSV(response, "all-users")
    //           return resolve(response.data)
    //         })
    //         .catch((error) => {
    //           commit('changeUserExportAllLoading', false)
    //           ctx.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Something went wrong, Please try again!',
    //               icon: 'AlertTriangleIcon',
    //               variant: 'danger'
    //             }
    //           })
    //           reject(error)
    //         })
    //     })
    //   },
    //   exportUsers({ commit }, { ctx, payload }) {
    //     commit('changeUserExportLoading', true)
    //     return new Promise((resolve, reject) => {
    //       axiosIns
    //         .post(defaultConfig.exportUserEndPoint({startDate: payload.startDate, endDate: payload.endDate}))
    //         .then((response) => {
    //           commit('changeUserExportLoading', false)
    //           downloadCSV(response, startDate+ "-to-" + endDate + "-users")
    //           return resolve(response.data)
    //         })
    //         .catch((error) => {
    //           commit('changeUserExportLoading', false)
    //           ctx.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Something went wrong, Please try again!',
    //               icon: 'AlertTriangleIcon',
    //               variant: 'danger'
    //             }
    //           })
    //           reject(error)
    //         })
    //     })
    //   },
    //  downloadCSV(response, name) {
    //     return new Promise( async (resolve, reject) => {
    //     if (response.ok) {
    //       // Convert the CSV data to Blob
    //       const csvBlob = await response.blob();

    //       // Create a Blob URL
    //       const blobUrl = URL.createObjectURL(csvBlob);

    //       // Create a download link
    //       const a = document.createElement('a');
    //       a.href = blobUrl;
    //       a.download = name+'.csv'; // Specify the filename
    //       a.style.display = 'none';
    //       document.body.appendChild(a);

    //       // Trigger the download
    //       a.click();

    //       // Clean up by revoking the Blob URL
    //       URL.revokeObjectURL(blobUrl);

    //       // Remove the download link from the DOM
    //       document.body.removeChild(a);
    //     } else {
    //       console.error('Failed to export data');
    //     }
    //     return true
    //   })
    //   }
  }
}
