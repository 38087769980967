<template>
  <b-sidebar
    id="add-new-plan-sidebar"
    :visible="isAddNewPlanSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-plan-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New Plan</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Plan Name -->
          <validation-provider
            #default="validationContext"
            name="Plan Name"
            rules="required"
          >
            <b-form-group label="Plan Name" label-for="plan-name">
              <b-form-input
                id="plan-name"
                v-model="planData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Plan Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider
            #default="validationContext"
            name="Type"
            rules="required"
          >
            <b-form-group
              label="Type"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="planData.is_free"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="type"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Price -->
          <validation-provider
            #default="validationContext"
            name="Price"
            rules="required"
            v-if="!planData.is_free"
          >
            <b-form-group label="Price" label-for="contact">
              <b-form-input
                id="price"
                v-model="planData.price"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Duration -->
          <validation-provider
            #default="validationContext"
            name="Duration"
            rules="required"
          >
            <b-form-group
              label="Duration"
              label-for="duration"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="planData.period_days"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="durationOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="duration"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Type -->
          <validation-provider
            #default="validationContext"
            name="Court Type"
            rules="required"
            v-if="!planData.is_free"
          >
            <b-form-group
              label="Court Type"
              label-for="court-type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="planData.court_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="courtTypeOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="court-type"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            v-if="!planData.is_free && planData.court_type == 'hc'"
            label="Select High Courts"
            label-for="high-court"
          >
            <v-select
              v-model="planData.court"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="courtOptions"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select High Courts"
              input-id="high-court"
            />
          </b-form-group>
          <validation-provider
            #default="validationContext"
            name="GST Included"
            rules="required"
            v-if="!planData.is_free"
          >
            <b-form-group label="GST Included" label-for="gst">
              <v-select
                v-model="planData.gstIncluded"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="gstOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="gst"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import defaultConfig from '@/configs/defaultConfig'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isAddNewPlanSidebarActive',
    event: 'update:is-add-new-plan-sidebar-active'
  },
  props: {
    isAddNewPlanSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries
    }
  },
  setup(props, context) {
    const typeOptions = [
      { label: 'Free', value: true },
      { label: 'Paid', value: false }
    ]

    const gstOptions = [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]

    const courtTypeOptions = [
      { label: 'Supreme Court', value: 'sci' },
      { label: 'High Court', value: 'hc' },
      { label: 'District Court', value: 'ecourts-district' },
      { label: 'Tribunals', value: 'tribunals' },
      { label: 'Others', value: 'other' }
    ]

    const durationOptions = [
      { label: '1 Day', value: 1 },
      { label: '7 Days', value: 7 },
      { label: '10 Days', value: 10 },
      { label: '15 Days', value: 15 },
      { label: '21 Days', value: 21 },
      { label: '1 Month', value: 30 },
      { label: '3 Month', value: 90 },
      { label: '6 Month', value: 180 },
      { label: '1 Year', value: 365 },
      { label: '2 Years', value: 730 },
      { label: '3 Years', value: 1095 },
      { label: '4 Years', value: 1460 },
      { label: '5 Years', value: 1825 }
    ]

    const blankplanData = {
      name: '',
      price: 0,
      period_days: null,
      is_free: false,
      enable: true,
      court_type: 'other',
      court: null,
      gstIncluded: false
    }

    const planData = ref(JSON.parse(JSON.stringify(blankplanData)))

    const resetplanData = () => {
      planData.value = JSON.parse(JSON.stringify(blankplanData))
    }

    const onSubmit = () => {
      let payload = planData.value

      if (payload.is_free) {
        payload['price'] = 0
      } else {
        payload['price'] = parseInt(payload['price'])
      }

      let slug = payload.name
        .toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, `-and-`) // & to and
        .replace(/--/g, `-`) // -- to -

      payload['reference_id'] = slug
      payload['slug'] = slug
      payload['court'] = payload['court_type'] == 'hc' ? payload['court'] : null
      store
        .dispatch('planStore/addPlan', { payload: payload })
        .then((response) => {
          if (response != null) {
            context.emit('refetch-data')
            context.emit('update:is-add-new-plan-sidebar-active', false)
          }
        })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetplanData)

    let courtOptions = []

    let ll = JSON.parse(localStorage.getItem(defaultConfig.allCourtsKeyName))
    console.log(ll)
    if (ll) {
      courtOptions = ll.highCourts.map(function (key) {
        return {
          value: key._id,
          label: key.title,
          cslug: key.cli_slug,
          unique_id: key.unique_id
        }
      })
    }

    return {
      planData,
      onSubmit,
      courtOptions,
      gstOptions,
      refFormObserver,
      getValidationState,
      resetForm,
      typeOptions,
      courtTypeOptions,
      durationOptions
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-plan-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
