import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
import store from '@/store/index.js'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMattersList({ id }) {
  // Use toast
  // const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'unique_id', label: 'Case Ref. Number', sortable: false },
    { key: 'datetime', label: 'Date', sortable: false },
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    let queryParams = {
      perPage: perPage.value,
      page: currentPage.value,
      user_id: id,
      type: statusFilter.value ?? "Upcoming"
    }

    store
      .dispatch('causelistStore/fetchCauseList', {
        ctx: this,
        queryParams: queryParams
      })
      .then((response) => {
        console.log({ Matters: response })
        callback(response.data)
        if(statusFilter.value === 'Archived'){
          totalInvoices.value = response.meta.total
          perPage.value = 10
        }else{
          totalInvoices.value = response.data.length
          perPage.value = response.data.length
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    refetchData
  }
}
