import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    courtsData: {},
    allCourts: [],
    allCourtList: [],
    courtsLoading: false
  },
  getters: {

  },
  mutations: {
    changeCourtLoading(state, val) {
      state.courtsLoading = val
    },
    setAllCourtsToLocal(state, payload) {
      state.courtsData = payload.data
      localStorage.setItem(
        defaultConfig.allCourtsKeyName,
        JSON.stringify(payload.data)
      )
    },
    setAllCourts(state, payload) {
      state.allCourts = payload.courtsList
      state.allCourtList = payload.allC
    }
  },
  actions: {
    fetchAllCourts({ commit }, { ctx, queryParams }) {
      console.log('Fetching All Courts -->', queryParams)
      commit('changeCourtLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getAllCourts, { params: queryParams })
          .then((response) => {
            commit('changeCourtLoading', false)
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeCourtLoading', false)
            reject(error)
          })
      })
    },
    
    /// Global Courts
    fetchCourts({ commit }, ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.allCourtEndPoint)
          .then((response) => {
            if (response.data.success) {
              commit('setAllCourtsToLocal', response.data)
            }
          })
          .catch(console.log)
      })
    },
    getAllCourts({ commit }, ctx) {
      console.log("Started")
      let allC = []
      const courtsList = []
      let ll = JSON.parse(localStorage.getItem(defaultConfig.allCourtsKeyName))
      if (ll) {
        this.courtsData = ll
        const scl = ll.supremeCourts.map(function (key) {
          return { value: key._id, text: key.title, cslug: key.cli_slug }
        })
        const sc = {
          label: 'Supreme Court',
          options: scl
        }
        const hcl = ll.highCourts.map(function (key) {
          return { value: key._id, text: key.title, cslug: key.cli_slug }
        })
        const hc = {
          label: 'High Court',
          options: hcl
        }
        const dcl = ll.districtCourts.map(function (key) {
          return { value: key._id, text: key.title, cslug: key.cli_slug }
        })
        const dc = {
          label: 'District Court',
          options: dcl
        }
        const tcl = ll.tribunalsCourts.map(function (key) {
          return { value: key._id, text: key.title, cslug: key.cli_slug }
        })
        const tc = {
          label: 'Tribunals Court',
          options: dcl
        }
        courtsList.push(sc)
        courtsList.push(hc)
        courtsList.push(dc)
        courtsList.push(tc)
        allC = [...scl, ...hcl, ...dcl, ...tcl]

        let payload = {
          courtsList: courtsList,
          allC: allC
        }

        commit('setAllCourts', payload)
      }
      return allC
    },

    getAllHeadCourts({ commit }, ctx) {
      console.log("Started")
      let allC = []
      const courtsList = []
      let ll = JSON.parse(localStorage.getItem(defaultConfig.allCourtsKeyName))
      if (ll) {
        this.courtsData = ll
        const scl = ll.supremeCourts.map(function (key) {
          return { value: key._id, text: key.title, cslug: key.cli_slug }
        })
        const sc = {
          label: 'Supreme Court',
          options: scl
        }
        const hcl = ll.highCourts.map(function (key) {
          return { value: key._id, text: key.title, cslug: key.cli_slug }
        })
        const hc = {
          label: 'High Court',
          options: hcl
        }
        courtsList.push(sc)
        courtsList.push(hc)
        allC = [...scl, ...hcl]

        let payload = {
          courtsList: courtsList,
          allC: allC
        }

        commit('setAllCourts', payload)
      }
      return allC
    }
  }
}
