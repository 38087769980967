<template>
  <div>
    <plan-list-add-new
      :is-add-new-plan-sidebar-active.sync="isAddNewPlanSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h4>Plans Details</h4>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                type="search"
                debounce="500"
              /> -->
              <b-button
                variant="primary"
                @click="isAddNewPlanSidebarActive = true"
              >
                <span class="text-nowrap">Add Plan</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchPlans"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <h6 class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </h6>
        </template>

        <!-- Column: Status -->
        <template #cell(enable)="data">
          <b-form-checkbox
            v-if="data.item.enable"
            id="accountSwitch1"
            :checked="true"
            name="check-button"
            variant="success"
            :disabled="true"
            switch
            @click.native="changePlanStatus(false, data.item.reference_id)"
            inline
          >
          </b-form-checkbox>
          <b-form-checkbox
            v-else
            id="accountSwitch1"
            :checked="false"
            name="check-button"
            variant="success"
            :disabled="true"
            switch
            @click.native="changePlanStatus(true, data.item.reference_id)"
            inline
          >
          </b-form-checkbox>
        </template>

        <template #cell(price)="data">
          <p>₹ {{ data.item.price }}</p>
        </template>

        <template #cell(is_free)="data">
          <template v-if="data.item.is_free">
            <b-badge variant="light-success"> Free </b-badge>
          </template>
          <template v-else>
            <b-badge variant="light-info"> Paid </b-badge>
          </template>
        </template>

        <!-- Column: Role -->
        <!-- <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template> -->

        <!-- Column: Status -->
        <!-- <template #cell(email)="data">
          <p class="text-nowrap m-0 font-weight-bold">{{ data.item.email }}</p>
          <small class="text-secondary font-italic">{{
            data.item.court.label
          }}</small>
        </template>

        <template #cell(mobile)="data">
          <p class="text-nowrap m-0 font-weight-bold">{{ data.item.mobile }}</p>
        </template> -->
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import usePlansList from './usePlansList'
import PlanListAddNew from './PlanListAddNew.vue'
import store from '@/store'

export default {
  components: {
    PlanListAddNew,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  setup() {
    const isAddNewPlanSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Active', value: 'true' },
      { label: 'Inactive', value: 'false' }
    ]

    function changePlanStatus(enabled, reference_id) {
      console.log(enabled)
      let str = enabled ? 'enable' : 'disable'
      this.$swal({
        title: 'Are you sure?',
        text: 'You really want to ' + str + ' this plan',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, ' + str + ' it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          store
            .dispatch('planStore/updatePlan', {
              payload: { reference_id: reference_id, enable: enabled }
            })
            .then((response) => {
              console.log('US', response)
              if (response !== null) {
                this.$swal({
                  icon: 'success',
                  title: enabled ? 'Enabled!' : 'Disabled!',
                  text: enabled
                    ? 'Plan has been enabled!'
                    : 'Plan has been disabled.',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
                this.refetchData()
              } else {
                this.refetchData()
              }
            })
        } else {
          this.refetchData()
        }
      })
    }

    const {
      fetchPlans,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      statusFilter
    } = usePlansList()

    return {
      // Sidebar
      isAddNewPlanSidebarActive,
      changePlanStatus,

      fetchPlans,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusOptions,
      statusFilter
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
