<template>
  <div v-if="data.category != null">
    <section>
      <h3 class="pb-1">Category</h3>
      <b-card>
        <!-- First Hearing Date -->
        <b-row>
          <b-col class="col-12 col-sm-7">
            <p class="m-0" v-if="Object.keys(data.category).includes('name')">
              {{ data.category.name }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
          <b-col class="col-12 col-sm-5">
            <p class="m-0" v-if="Object.keys(data.category).includes('value')">
              {{ data.category.value }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
        </b-row>
      </b-card>
    </section>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  }
}
</script>
