import moment from 'moment'
export default {
  // End Points ==============

  baseUrl: 'https://admin.mymunshi.in',
  getAllCourts: '/api/model/high_courts?sort=type&order=1',

  getAllUsers: '/api/model/users',

  getUsersTribunals: '/api/model/user_courts',
  
  deleteTribunal: '/api/user_courts',

  addTribunal: '/api/user_courts/create',

  updateTribunal: '/api/user_courts',

  getUsersPlan: '/api/model/users?group=plan.reference_id,plan.name',

  getPlanUsersAnalytics:
    '/api/model/users?group=plan.reference_id,plan.name,plan.is_free',

  // Get All Courts
  allCourtEndPoint: 'https://app.mymunshi.in/api/courts/all',
  // Update User
  userUpdate: '/api/admin/update',

  sendGeneralPush: '/api/support/send_alert',

  sendOTP: '/api/reset/request_otp',

  verifyOTP: '/api/reset/verify',

  resetPassword: '/api/reset/change',

  updateAdminEndPoint: '/api/admin/update',

  // Matters
  getMattersEndPoint: '/api/model/matters',
  // Get Matter By Id
  getMatterEndPoint({ id }) {
    return '/api/matters/' + id
  },

  getMatterByIdEndPoint({ id }) {
    return '/api/model/matters?' + id
  },

  updateMatterPublicDataEndPoint({ id }) {
    return '/api/matters/public_data/' + id
  },

  // Get Matter Judgments
  getJudgmentEndPoint: '/api/model/matters_judgement',
  allJudgmentExportEndPoint({ id, user_id }) {
    return '/api/model/orders/exports?matter_id=' + id + '&user_id=' + user_id
  },
  // Find Matter
  findMatterEndPoint: '/api/courts/find',
  // Save Matter
  saveMatterEndPoint: '/api/matters',
  saveNewUserEndPoint: '/api/users/register',
  updateUserEndPoint: '/api/users/update',
  exportUserEndPoint({ startDate, endDate }) {
    return (
      '/api/model/users?time=time&from=' +
      startDate +
      '&to=' +
      endDate +
      '&export=true&fields=fullName,email,mobile,court.label,address,time,expires_at,plan.name,plan.price,comment&limit=100000'
    )
  },
  exportAllUserEndPoint:
    '/api/model/users?time=time&export=true&fields=fullName,email,mobile,court.label,address,time,expires_at,plan.name,plan.price,comment&limit=100000',
  // Delete Matter
  deleteMatterEndPoint({ id }) {
    return '/api/matters/delete/' + id
  },

  getTrackerCount({ type }) {
    return '/api/model/matters?group=' + type
  },

  // Get Matter Notes
  getMatterNotesEndPoint({ id }) {
    return '/api/matters/list_notes/' + id
  },
  // Save Matter Note
  saveMatterNoteEndPoint: '/api/matters/create_notes',
  // Update Matter Note
  updateMatterNoteEndPoint: '/api/matters/update_notes',
  // Delete Matter Note
  deleteMatterNoteEndPoint({ id }) {
    return '/api/matters/delete_notes/' + id
  },

  // Get Matter Docs
  getMatterDocsEndPoint({ id }) {
    return '/api/matters/list_document/' + id
  },
  // Save Matter Doc
  saveMatterDocEndPoint: '/api/matters/create_document',
  // Delete Matter Doc
  deleteMatterDocEndPoint({ id }) {
    return '/api/matters/delete_document/' + id
  },

  // Get Causelist
  getHearingEndPoint: '/api/hearing',

  hearingExportByDateEndPoint({ date, user_id }) {
    return '/api/model/hearing/exports?date=' + date + '&user_id=' + user_id
  },

  // Get Archived Causelist
  getCauselistEndPoint(queryParams) {
    if (queryParams.type === 'Archived') {
      var startdate = moment()
      startdate = startdate.subtract(30, 'days')
      startdate = startdate.format('YYYY-MM-DD')

      var enddate = moment()

      var hours = moment().local().hour()

      if (hours < 18) {
        enddate = enddate.subtract(1, 'days')
      }

      enddate = enddate.format('YYYY-MM-DD')

      console.log('Dates', startdate, enddate)

      return (
        '/api/model/causelist_history?filters[user_id]=' +
        queryParams.user_id +
        '&from=' +
        startdate +
        '&to=' +
        enddate +
        '&time=datetime&sort=datetime&order=-1&perPage=' +
        queryParams.perPage +
        '&page=' +
        queryParams.page
      )
    } else {
      return '/api/model/hearing?user_id=' + queryParams.user_id
    }
  },

  // Get Display Board
  getDisplayBoardEndPoint({ id }) {
    return '/api/display_board/' + id
  },

  getTicketsEndPoint: '/api/model/support_tickets',

  updateTicketEndPoint: '/api/support/ticket/update',

  getInquiriesEndPoint: '/api/model/enquiries',

  exportEnquiriesEndPoint({ startDate, endDate }) {
    return (
      '/api/model/enquiries?time=datetime&from=' +
      startDate +
      '&to=' +
      endDate +
      '&export=true&fields=fullName,email,phone_number,state,district,status,datetime,comment&limit=100000'
    )
  },
  exportAllEnquiriesEndPoint:
    '/api/model/enquiries?time=datetime&export=true&fields=fullname,email,phone_number,state,district,status,datetime,comment&limit=100000',

  updateInquiryEndPoint: '/api/support/enqury/update',

  getPlansEndPoint: '/api/model/plans?page=1&perPage=100',
  createPlansEndPoint: '/api/plans/create',
  updatePlansEndPoint: '/api/plans/update',
  applyPlanEndPoint({ plan_reference_id }) {
    return '/api/plans/apply/' + plan_reference_id
  },

  getPlanTransactionEndPoint({ id }) {
    return '/api/model/transactions?filters[user_id]=' + id
  },

  getNotificationEndPoint: '/api/model/notification_logs',

  // Local Key Names
  allCourtsKeyName: 'allcourts',
  displayBoardKeyName: 'displayBoard',
  usersPlanKeyName: 'usersPlan'
}
