<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h5>Court List</h5>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <b-row class="d-flex align-items-center justify-content-end">
            <b-col cols="8">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                type="search"
                debounce="500"
              />
            </b-col>
            <b-col cols="4">
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-overlay
      :show="$store.state.courtStore.courtsLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <b-table
        ref="refInvoiceListTable"
        :items="fetchInvoices"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Column: Balance -->
        <template #cell(type)="data">
          <template v-if="data.item.type === 'sci'">
            <b-badge variant="light-success"> Supreme Court </b-badge>
          </template>
          <template v-else-if="data.item.type === 'hc'">
            <b-badge variant="light-info"> High Court </b-badge>
          </template>
          <template v-else-if="data.item.type === 'tribunals'">
            <b-badge variant="light-secondary"> Tribunals </b-badge>
          </template>
          <template v-else-if="data.item.type === 'ecourts-district'">
            <b-badge variant="light-secondary"> District </b-badge>
          </template>
          <template v-else> </template>
        </template>
        <template #cell(title)="data">
          <div>
            <div class="mb-1 text-primary">
              <h6 class="text-dark">
                {{ data.item.title }}
              </h6>
            </div>
          </div>
        </template>

        <!-- Column: Balance -->
        <!-- <template #cell(case_types)="data">
          <div v-if="Object.keys(data.item).includes('case_types')">
            <div v-if="data.item.case_types !== null">
              <small
                class="m-0 text-dark cursor-pointer"
                v-b-tooltip.hover
                title="View Types"
                v-b-modal="'myModal' + data.item._id"
              >
                {{ data.item.case_types.length }} Case Types
                <span>
                  <feather-icon icon="EyeIcon" size="12" class="ml-25" />
                </span>
              </small>
              <b-modal
                :id="'myModal' + data.item._id"
                scrollable
                ok-only
                ok-title="Close"
                :title="'Types of '+data.item.title"
                ok-variant="outline-secondary"
              >
                <b-card-text
                  v-for="(content, index) in data.item.case_types"
                  :key="index"
                >
                  <b-list-group-item class="flex-column align-items-start">
                    <b-card-text class="mb-0 font-weight-bold">
                      {{ content.name }}
                    </b-card-text>
                    <small class="text-secondary"
                      >{{ content.value }} - <small class="text-info">({{ content.key }})</small></small
                    >
                  </b-list-group-item>
                </b-card-text>
              </b-modal>
            </div>
            <small class="m-0" v-else></small>
          </div>
          <small class="m-0" v-else></small>
        </template> -->

        <!-- Column: Balance -->
        <template #cell(enabled)="data">
          <template v-if="data.item.enabled">
            <b-badge pill variant="primary"> Active </b-badge>
          </template>
          <template v-else>
            <b-badge pill variant="danger"> In-Active </b-badge>
          </template>
        </template>
      </b-table>
    </b-overlay>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BModal,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BPagination,
  BTooltip,
  BOverlay,
  VBTooltip,
  VBModal
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

// import store from '@/store'
import useCourtsList from './useCourtsList'

export default {
  components: {
    BCard,
    BRow,
    BModal,
    BCardText,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BListGroup,
    BListGroupItem,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BOverlay,

    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple
  },
  props: {
    type: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      matterType: this.type
    }
  },
  beforeMount() {},
  methods: {
    getNextDate({ dates }) {
      var date = '--'
      if (Array.isArray(dates)) {
        if (dates.length > 0) {
          var dd = []
          for (let i = 0; i < dates.length; i++) {
            dd.push(moment(dates[i]).format('Do, MMMM YYYY'))
          }
          date = dd.join(', ')
        }
      } else {
        date = dates
      }
      return date
    },
    // delete alert
    deleteAlert(_id) {
      this.$swal({
        title: 'Warning',
        icon: 'warning',
        text: 'You really want to delete this matter',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('matterStore/deleteMatter', {
              ctx: this,
              _id: _id
            })
            .then((result) => {
              this.refetchData()
            })
        }
      })
    }
  },

  setup(props) {
    const statusOptions = ['Enabled', 'Disabled']

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData
    } = useCourtsList({ type: props.type })

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      statusOptions,
      refetchData,
      formatDate,

      avatarText
    }
  }
}
</script>
