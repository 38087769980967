<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isUpdateUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-update-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Update User</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group label="Full Name" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="userData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Full Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="Mobile"
            rules="required|regex:^([0-9]+)$|min:10"
          >
            <b-form-group label="Mobile" label-for="mobile">
              <b-form-input
                id="mobile"
                v-model="userData.mobile"
                placeholder="0987654321"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label="Alternate Mobiles"
            label-for="user-alternate-numbers"
          >
            <b-form-tags
              v-model="userData.alternate_numbers"
              placeholder="Add Alternate Mobiles"
              input-id="user-alternate-numbers"
            />
            <b-form-invalid-feedback :state="false" class="text-info">
              Type and press enter to push new number.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Alternate Emails"
            label-for="user-alternate-emails"
          >
            <b-form-tags
              v-model="userData.alternate_emails"
              placeholder="Add Alternate Emails"
              input-id="user-alternate-emails"
            />
            <b-form-invalid-feedback :state="false" class="text-info">
              Type and press enter to push new email.
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Address -->
          <validation-provider
            #default="validationContext"
            name="Address"
            rules="required"
          >
            <b-form-group
              label="Address"
              label-for="address"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="address"
                placeholder="Office Address"
                v-model="userData.address"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="Lawyer Names" label-for="user-lawyers">
            <b-form-tags
              v-model="userData.name_filters"
              placeholder="Add Lawyer Names"
              input-id="user-lawyers"
            />
            <b-form-invalid-feedback :state="false" class="text-info">
              Type and press enter to push new name.
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BFormTags,
  BInputGroupAppend
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTags,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isUpdateUserSidebarActive',
    event: 'update:is-update-user-sidebar-active'
  },
  props: {
    isUpdateUserSidebarActive: {
      type: Boolean,
      required: true
    },
    courtOptions: {
      type: Array,
      required: true
    },
    districtOptions: {
      type: Array,
      required: true
    },
    tribunalOptions: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      required
    }
  },
  computed: {},
  methods: {},
  setup(props, context) {
    console.log('Adv Names', props.data.name_filters)
    var courtOption = props.courtOptions
    var districtOption = props.districtOptions
    var tribunalOption = props.tribunalOptions
    const blankUserData = {
      user_id: props.data._id,
      fullName: props.data.fullName,
      court_id: props.data.court_id,
      district_court_id: props.data.district_court_id,
      tribunal_court_id: props.data.tribunal_court_id,
      advocate_key_id: props.data.advocate_key_id,
      district_advocate_key_id: props.data.district_advocate_key_id,
      tribunal_advocate_key_id: props.data.tribunal_advocate_key_id,
      sc_advocate_key_id: props.data.sc_advocate_key_id,
      address: props.data.address,
      tribunals:
        Object.keys(props.data).includes('tribunals') &&
        props.data.tribunals !== null
          ? props.data.tribunals
          : {
              cat: {
                id: '',
                aor: ''
              },
              confonet: {
                id: '',
                aor: ''
              },
              drt: {
                id: '',
                aor: ''
              },
              rera: {
                id: '',
                aor: ''
              },
              reat: {
                id: '',
                aor: ''
              }
            },
      test_user: true,
      mobile: props.data.mobile,
      alternate_numbers:
        Object.keys(props.data).includes('alternate_numbers') &&
        props.data['alternate_numbers'] != null
          ? props.data.alternate_numbers
          : [],
        alternate_emails:
        Object.keys(props.data).includes('alternate_emails') &&
        props.data['alternate_emails'] != null
          ? props.data.alternate_emails
          : [],
      name_filters: props.data.name_filters
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store
        .dispatch('userStore/updateUser', { payload: userData.value })
        .then((response) => {
          if (response != null) {
            context.emit('refetch-data')
            context.emit('update:is-update-user-sidebar-active', false)
          }
        })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      tribunalOption,
      courtOption,
      districtOption,
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
