<template>
  <b-card>
    <b-row>
      <!-- subscription -->
      <b-col md="6">
        <b-card
          border-variant="success"
          class="m-0 mb-md-0 mb-1"
          no-body
          header-bg-variant="success"
        >
          <template #header>
            <b-col>
              <b-row class="justify-content-between">
                <b-col class="p-0 m-0">
                  <h5 class="font-weight-bolder m-0 text-white">
                    Current Plan
                  </h5>
                </b-col>
                <b-col>
                  <b-row class="justify-content-end">
                    <b-badge variant="primary" class="badge-round">
                      <feather-icon
                        :id="`plan-type`"
                        icon="CheckCircleIcon"
                        size="16"
                        class="mr-25 cursor-pointer"
                      />
                      <span>Premium</span>
                    </b-badge>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </template>
          <b-row class="p-75">
            <b-col class="col-6">
              <h6 class="m-0">Plan Type</h6>
            </b-col>
            <b-col class="col-6 text-right">
              <p class="m-0">Full Paid</p>
            </b-col>
          </b-row>
          <hr style="margin: 0" />
          <b-row class="p-75">
            <b-col class="col-6">
              <h6 class="m-0">Plan Name</h6>
            </b-col>
            <b-col class="col-6 text-right">
              <p class="m-0">Premium</p>
            </b-col>
          </b-row>
          <hr style="margin: 0" />
          <b-row class="p-75">
            <b-col class="col-6">
              <h6 class="m-0">Payment Cycle</h6>
            </b-col>
            <b-col class="col-6 text-right">
              <p class="m-0">Yearly</p>
            </b-col>
          </b-row>
          <hr style="margin: 0" />
          <b-row class="p-75">
            <b-col class="col-6">
              <h6 class="m-0">Exp. Date</h6>
            </b-col>
            <b-col class="col-6 text-right">
              <p class="m-0">23, Jan 2024</p>
            </b-col>
          </b-row>
          <hr style="margin: 0" />
          <b-row class="p-75">
            <b-col class="col-6">
              <h6 class="m-0">Days Left</h6>
            </b-col>
            <b-col class="col-6 text-right">
              <p class="m-0">364</p>
            </b-col>
          </b-row>

          <template #footer>
            <b-row class="justify-content-center">
              <b-button href="#" variant="success">Upgrade Plan</b-button>
            </b-row>
          </template>
        </b-card>
      </b-col>
      <!--/ subscription -->
      <!-- sales contact -->
      <b-col md="6">
        <b-card
          border-variant="primary"
          class="m-0"
          header-bg-variant="primary"
          no-body
        >
          <template #header>
            <b-col>
              <b-row class="justify-content-between">
                <b-col class="p-0 m-0">
                  <h5 class="font-weight-bolder m-0 text-white">
                    Sales Support
                  </h5>
                </b-col>
              </b-row>
            </b-col>
          </template>
          <b-row class="p-75">
            <b-col class="col-6">
              <h6 class="m-0">Name:</h6>
            </b-col>
            <b-col class="col-6 text-right">
              <p class="m-0">Support Name</p>
            </b-col>
          </b-row>
          <hr style="margin: 0" />
          <b-row class="p-75">
            <b-col class="col-5">
              <h6 class="m-0">Call Us:</h6>
            </b-col>
            <b-col class="col-7 text-right">
              <a href="tel:+919898989898" class="m-0">+91 90058 18623</a>
            </b-col>
          </b-row>
          <hr style="margin: 0" />
          <b-row class="p-75">
            <b-col class="col-5">
              <h6 class="m-0">Email Us:</h6>
            </b-col>
            <b-col class="col-7 text-right">
              <a href="mailto: support@mymunshi.in" class="m-0">support@mymunshi.in</a>
            </b-col>
          </b-row>
          <hr style="margin: 0" />
          <b-row class="p-75">
            <b-col class="col-5">
              <h6 class="m-0">WhatsApp:</h6>
            </b-col>
            <b-col class="col-7 text-right">
              <a target="_blank" href="https://wa.me/919005818623" class="m-0">+91 90058 18623</a>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!--/ sales contact -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BBadge
  },
  directives: {
    Ripple
  },
  data() {
    return {
      localOptions: JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    resetForm() {
      this.localOptions = JSON.parse(localStorage.getItem('userData'))
    }
  }
}
</script>
