<template>
  <section id="dashboard-analytics">
    <analytics-plan-users ></analytics-plan-users>

    <b-row>
      <b-col lg="6">
        <analytics-count></analytics-count>
      </b-col>
      <b-col lg="6">
        <analytics-count-yearly></analytics-count-yearly>
        
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col>
        <analytics-timeline :data="data.timeline" />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'

import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import AnalyticsPlanUsers from './AnalyticsPlanUsers.vue'
import AnalyticsCount from './AnalyticsCount.vue'
import AnalyticsCountYearly from './AnalyticsCountYearly.vue'


export default {
  components: {
    BRow,
    BCol,
    AnalyticsSupportTracker,
    AnalyticsPlanUsers,
    AnalyticsCount,
    AnalyticsCountYearly
  },
  data() {
    return {
      data: {}
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data').then((response) => {
      this.data = response.data
    })
    this.$store.dispatch('userStore/fetchUsersPlan')
  },
  methods: {
    kFormatter
  }
}
</script>
