import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    plansLoading: false,
    plansList: [],
    transactionList: [],
    plansUpgradeList: [],
    plansZeroList: [],
    planFormLoading: false
  },
  getters: {
    // getAllMatters: (state) => {
    //   return state.allMatters
    // }
  },
  mutations: {
    changePlansLoading(state, val) {
      state.plansLoading = val
    },
    setPlansListData(state, payload) {
      state.plansList = payload.filter((item) => {
        return item.enable === true
      })
      state.plansUpgradeList = payload.filter((item) => {
        return item.enable === true && item.price > 0
      })
      state.plansZeroList = payload.filter((item) => {
        return item.enable === true && item.price === 0
      })
    },
    setTransactionData(state, payload) {
      state.transactionList = payload
    },
    changePlanFormLoading(state, val) {
      state.planFormLoading = val
    }
  },
  actions: {
    fetchPlans({ commit }) {
      commit('changePlansLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getPlansEndPoint)
          .then((response) => {
            commit('changePlansLoading', false)
            console.log({ FetchingPlans: JSON.stringify(response.data) })
            if (response.data.success) {
              commit('setPlansListData', response.data.data)
            }
            resolve(response.data)
          })
          .catch((error) => {
            commit('changePlansLoading', false)
            reject(error)
          })
      })
    },
    fetchTransactions({ commit }, {id}) {
      console.log("PLan Id ", id)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getPlanTransactionEndPoint({ id: id }))
          .then((response) => {
            console.log({ FetchingPlans: JSON.stringify(response.data) })
            if (response.data.success) {
              commit('setTransactionData', response.data.data)
            }
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // Save Plan
    addPlan({ commit }, { payload }) {
      commit('changePlanFormLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.createPlansEndPoint, payload)
          .then((response) => {
            commit('changePlanFormLoading', false)
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Plan saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changePlanFormLoading', false)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Update Plan
    updatePlan({ commit }, { payload }) {
      commit('changePlanFormLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.updatePlansEndPoint, payload)
          .then((response) => {
            commit('changePlanFormLoading', false)
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Updated successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              Vue.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changePlanFormLoading', false)
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    }
  }
}
