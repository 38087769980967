
export default [
  {
    path: '/dashboard/analytics',
    name: 'analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/courts/courts-list',
    name: 'court-list',
    component: () => import('@/views/courts/courts-list/court_list.vue'),
    meta: {
      pageTitle: 'Courts',
      breadcrumb: [
        {
          text: 'Court List',
          active: true,
        }
      ],
    },
  },
    // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/users/users-list',
    name: 'users-list',
    component: () => import('@/views/users/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'User List',
          active: true,
        }
      ],
    },
  },
  {
    path: '/users/users-view/:id',
    name: 'users-view',
    component: () => import('@/views/users/users-view/UsersView.vue'),
  },
  {
    path: 'users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/users/users-edit/UsersEdit.vue'),
  },
  {
    path: '/users/users-view/user-matter/matter-preview/:id',
    name: 'user-matter-preview',
    component: () => import('@/views/users/users-view/user-matter/matter-preview/MatterPreview.vue'),
    meta: {
      pageTitle: 'Matter Detail',
      breadcrumb: [
        {
          text: 'User',
          active: true,
        },
        {
          text: 'Matters',
          active: true,
        },
        {
          text: 'Preview',
          active: true,
        },
      ],
    },
  },
      // *===============================================---*
  // *--------- PLAN ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/plans/plans-list',
    name: 'plans-list',
    component: () => import('@/views/plans/plans-list/PlansList.vue'),
    meta: {
      pageTitle: 'Plans',
      breadcrumb: [
        {
          text: 'Plan List',
          active: true,
        }
      ],
    },
  },

        // *===============================================---*
  // *--------- DISPLAY BOARD ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/dashboard/entirecases',
    name: 'entire-cases',
    component: () => import('@/views/dashboard/entirecases/EntireCases.vue'),
    meta: {
      pageTitle: 'Entire Cases',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
        {
          text: 'Entire Cases',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/dashboard/notification',
  //   name: 'notification-screen',
  //   component: () => import('@/views/dashboard/notification/NotificationScreen.vue'),
  //   meta: {
  //     pageTitle: 'Notifications',
  //     breadcrumb: [
  //       {
  //         text: 'Notifications',
  //         active: true,
  //       }
  //     ],
  //   },
  // },
  {
    path: '/support/support-ticket',
    name: 'support-ticket',
    component: () => import('@/views/support/support-ticket.vue'),
    meta: {
      pageTitle: 'Support',
      breadcrumb: [
        {
          text: 'Support Tickets',
          active: true,
        }
      ],
    },
  },
  {
    path: '/inquiry/new-inquiry',
    name: 'new-inquiry',
    component: () => import('@/views/inquiry/new-inquiry.vue'),
    meta: {
      pageTitle: 'Inquiry',
      breadcrumb: [
        {
          text: 'New Inquiries',
          active: true,
        }
      ],
    },
  },
]
