import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import matterStore from './dashboard/matter-store'
import displayboardStore from './dashboard/displayboard-store'
import courtStore from './dashboard/court-store'
import settingStore from './dashboard/setting-store'
import causelistStore from './dashboard/causelist-store'
import notificationStore from './dashboard/notification-store'
import resetPasswordStore from './reset-password/reset-password-store'
import userStore from './dashboard/user-store'
import planStore from './dashboard/plan-store'
import ticketStore from './dashboard/ticket-store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    matterStore,
    causelistStore,
    displayboardStore,
    notificationStore,
    courtStore,
    settingStore,
    resetPasswordStore,
    userStore,
    planStore,
    ticketStore
  },
  strict: process.env.DEV,
})
