export default [
    {
        path: '/settings/account-setting',
        name: 'account-setting',
        component: () => import('@/views/settings/account-setting/AccountSetting.vue'),
        meta: {
          pageTitle: 'Account Settings',
          breadcrumb: [
            {
              text: 'Settings',
            },
            {
              text: 'Account Settings',
              active: true,
            },
          ],
        },
      },
      {
        path: '/push-notification',
        name: 'push-notification',
        component: () => import('@/views/push-notification/push-notification.vue'),
        meta: {
          pageTitle: 'Push Notification',
          breadcrumb: [
            {
              text: 'Push Notification',
              active: true,
            },
          ],
        },
      }
]