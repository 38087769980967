<template>
  <div v-if="optionsLocal">
    <b-card>
      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="optionsLocal.avatar"
              height="80"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            Change
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .jpeg, .png"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
          <!--/ upload button -->

          <!-- reset -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
          >
            Reset
          </b-button>
          <!--/ reset -->
          <b-card-text>Allowed JPG, JPEG or PNG only.</b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <b-form class="mt-2" @submit="submitForm">
        <b-row>
          <b-col sm="6">
            <b-form-group label="Name" label-for="account-name">
              <validation-provider
                #default="{ errors }"
                name="Name"
                vid="name"
                rules="required"
              >
                <b-form-input
                  v-model="optionsLocal.fullName"
                  name="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input
                v-model="optionsLocal.email"
                name="email"
                :disabled="true"
                placeholder="Email"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Mobile" label-for="account-mobile">
              <validation-provider
                #default="{ errors }"
                name="Mobile"
                vid="mobile"
                rules="required|regex:^([0-9]+)$|min:10"
              >
                <b-form-input
                  v-model="optionsLocal.mobile"
                  name="mobile"
                  type="number"
                  placeholder="Mobile"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr class="my-1" />
          </b-col>
          <!-- Action Buttons -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
            >
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
  <div v-else>Loading ...</div>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  data() {
    let userData = JSON.parse(localStorage.getItem('userData'))
    return {
      userData: userData,
      optionsLocal: userData,
      profileFile: null,
    }
  },
  beforeMount() {

  },
  methods: {
    submitForm() {
      event.preventDefault()
      if (
        /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(
          this.optionsLocal.mobile
        ) &&
        this.optionsLocal.fullName !== '' &&
        this.optionsLocal.address !== ''
      ) {

        const payload = {
          fullName: this.optionsLocal.fullName,
          address: this.optionsLocal.address,
          mobile: this.optionsLocal.mobile
        }
        console.log({ FORMDATA: JSON.stringify(payload) })
        console.log('Valid')
        this.$store.dispatch('settingStore/updateUser', {
          ctx: this,
          payload: payload
        })
      } else {
        console.log('Invalid')
      }
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
