<template>
  <section>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="light"
      class="ml-1"
      @click="fetchTribunals"
      v-b-toggle="'manage-tribunals'"
    >
      Tribunals
    </b-button>
    <template>
      <b-sidebar
        id="manage-tribunals"
        :visible="isUpdateTribunalsSidebarActive"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right
        @hidden="resetForm"
        @change="
          (val) => $emit('update:is-update-tribunals-sidebar-active', val)
        "
      >
        <template #default="{ hide }">
          <!-- Header -->
          <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
          >
            <h5 class="mb-0">Manage Tribunals</h5>

            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>

          <!-- BODY -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <!-- Form -->
            <b-form
              class="px-2 pt-1"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- Tribunals Court -->
              <validation-provider
                #default="validationContext"
                name="Select Tribunal"
                rules="required"
              >
                <b-form-group
                  label="Select Tribunal *"
                  label-for="user-tribunal-court"
                >
                  <v-select
                    v-model="formData.court_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tribunalOption"
                    :reduce="(val) => val.value"
                    :clearable="true"
                    placeholder="Select Tribunal"
                    input-id="user-tribunal-court"
                  />
                </b-form-group>
              </validation-provider>
              <!-- Advocate Name (For Tribunal Court) -->
              <validation-provider
                #default="validationContext"
                name="Filter Names"
                rules=""
              >
                <b-form-group label="Filter Names" label-for="filter_by_names">
                  <b-form-tags
                    v-model="formData.filter_by_names"
                    placeholder="Add Lawyer Names"
                    input-id="filter_by_names"
                  />
                  <b-form-invalid-feedback :state="false" class="text-info">
                    Type and press enter to push new name.
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="!formData.court_id"
                >
                  Add Tribunal
                </b-button>
              </div>
            </b-form>
          </validation-observer>
          <hr />
          <h6 class="pl-1">Tribunals List</h6>
          <b-list-group class="m-1">
            <div v-for="item in tribunalsData">
              <tribunal-court-item :tribunalData="item" :data="userData" @refetch-data="fetchTribunals">
              </tribunal-court-item>
            </div>
          </b-list-group>
        </template>
      </b-sidebar>
    </template>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BFormTags,
  BInputGroupAppend,
  BListGroup,
  BListGroupItem,
  VBToggle
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import TribunalCourtItem from './TribunalCourtItem.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTags,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BListGroup,
    BListGroupItem,
    TribunalCourtItem,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  model: {
    prop: 'isUpdateTribunalsSidebarActive',
    event: 'update:is-update-tribunals-sidebar-active'
  },
  props: {
    tribunalOptions: {
      type: Array,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      required
    }
  },
  computed: {},
  methods: {},
  setup(props, context) {
    var tribunalOption = props.tribunalOptions
    const isUpdateTribunalsSidebarActive = ref(false)
    var userData = props.data
    const blankUserData = {
      user_id: userData._id,
      court_id: null,
      filter_by_key: '',
      filter_by_names: []
    }

    const formData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      formData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const tribunalsData = ref(null)

    const fetchTribunals = () => {
      store
        .dispatch('userStore/fetchTribunalsById', { id: userData._id })
        .then((response) => {
          tribunalsData.value = null
          setTimeout(() => (tribunalsData.value = response), 100)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching user',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        })
    }

    const onSubmit = () => {
      store
        .dispatch('userStore/saveTribunal', { payload: formData.value })
        .then((response) => {
          if (response != null) {
            fetchTribunals()
          }
        })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)

    return {
      formData,
      onSubmit,
      userData,
      tribunalsData,
      tribunalOption,
      refFormObserver,
      getValidationState,
      resetForm,
      fetchTribunals,
      isUpdateTribunalsSidebarActive
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#manage-tribunals {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
