<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :court-options="courtOptions"
      :tribunal-options="tribunalOptions"
      :district-options="districtOptions"
      @refetch-data="refetchData"
    />

    <users-export/>

    <!-- Filters -->
    <users-list-filters
      :court-filter.sync="courtFilter"
      :status-filter.sync="statusFilter"
      :plan-status-filter.sync="planStatusFilter"
      :plan-filter.sync="planFilter"
      :court-options="[
        ...scCourtOptions,
        ...courtOptions,
        ...districtOptions,
        ...tribunalOptions
      ]"
      :status-options="statusOptions"
      :plan-options="planOptions"
      :plan-status-options="planStatusOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <h5>Users List</h5>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                type="search"
                debounce="500"
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(username)="data">
          <b-media vertical-align="center">
            <template #aside>
              <router-link
                :to="{
                  name: 'users-view',
                  params: { id: data.item._id }
                }"
                target="_blank"
                ><b-avatar
                  size="32"
                  rounded
                  variant="light"
                  :src="data.item.avatar"
                  :text="avatarText(data.item.fullName)"
              /></router-link>
            </template>
            <router-link
              :to="{
                name: 'users-view',
                params: { id: data.item._id }
              }"
              target="_blank"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </router-link>
            <small v-if="Object.keys(data.item).includes('court')">
              <small v-if="data.item.court !== null"
                ><small
                  class="text-secondary font-italic"
                  v-if="Object.keys(data.item.court).includes('label')"
                >
                  {{ data.item.court.label }}
                </small></small
              >
            </small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(enabled)="data">
          <b-badge
            :variant="data.item.enabled ? 'light-info' : 'light-danger'"
            pill
            class="badge-round mr-1"
          >
            <span>{{ data.item.enabled ? 'Enabled' : 'Disabled' }}</span>
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(email)="data">
          <p class="text-nowrap m-0 font-weight-bold mb-50">
            {{ data.item.email }}
          </p>
          <p class="text-nowrap m-0 font-weight-bold">
            Phone: {{ data.item.mobile }}
          </p>
        </template>

        <template #cell(plan)="data">
          <p class="text-nowrap m-0 font-weight-bold mb-50">
            {{ data.item.plan.name }}
          </p>
          <b-badge
            :variant="
              getSubscriptionDate(data.item.expires_at).includes('Expired')
                ? 'light-danger'
                : 'light-info'
            "
            pill
            class="badge-round mr-1"
          >
            <span>{{ getSubscriptionDate(data.item.expires_at) }}</span>
          </b-badge>
        </template>

        <template #cell(time)="data">
          <p class="text-nowrap m-0 font-weight-bold mb-50">
            <span>{{ getDate(data.item.time) }}</span>
          </p>
        </template>

        <!-- <template #cell(mobile)="data">
          <p class="text-nowrap m-0 font-weight-bold">{{ data.item.mobile }}</p>
        </template> -->

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <router-link
            :to="{
              name: 'users-view',
              params: { id: data.item._id }
            }"
            target="_blank"
          >
            <b-badge variant="light-primary" pill class="badge-round mr-1">
              <feather-icon
                :id="`matter-${data.item._id}-preview-icon`"
                icon="FileTextIcon"
                size="16"
                class="mr-25"
              />
              <span>Details</span>
            </b-badge>
          </router-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import UserListAddNew from './UserListAddNew.vue'
import defaultConfig from '@/configs/defaultConfig'
import moment from 'moment'
import UsersExport from './UsersExport.vue'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    UsersExport,

    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  methods: {
    getSubscriptionDate(expires_at) {
      let expiredDate = moment(expires_at)
      if (expiredDate.isBefore(moment())) {
        return 'Expired On: ' + expiredDate.format('MMM Do YYYY')
      }
      return 'Expire On: ' + expiredDate.format('MMM Do YYYY')
    },
    getDate(time) {
      let expiredDate = moment(time)
      return expiredDate.format('MMM Do YYYY')
    }
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false)

    let scCourtOptions = []
    let courtOptions = []
    let districtOptions = []
    let tribunalOptions = []

    let ll = JSON.parse(localStorage.getItem(defaultConfig.allCourtsKeyName))
    if (ll) {
      scCourtOptions = ll.supremeCourts.map(function (key) {
        return {
          value: key._id,
          label: `${key.title} (${key.total_subscribers})`,
          cslug: key.cli_slug,
          unique_id: key.unique_id
          // total_subscribers: key.total_subscribers
        }
      })
      courtOptions = ll.highCourts.map(function (key) {
        return {
          value: key._id,
          label: `${key.title} (${key.total_subscribers})`,
          cslug: key.cli_slug,
          unique_id: key.unique_id
        }
      })
      districtOptions = ll.districtCourts.map(function (key) {
        return {
          value: key._id,
          label: `${key.title} (${key.total_subscribers})`,
          cslug: key.cli_slug,
          unique_id: key.unique_id
        }
      })
      tribunalOptions = ll.tribunalsCourts.map(function (key) {
        return {
          value: key._id,
          label: `${key.title} (${key.total_subscribers})`,
          cslug: key.cli_slug,
          unique_id: key.unique_id
        }
      })
    }

    let planOptions = []

    let plans = JSON.parse(localStorage.getItem(defaultConfig.usersPlanKeyName))
    if (plans) {
      planOptions = plans.map(function (key) {
        return {
          value: key.reference_id,
          label: `${key.name} (${key.count})`
        }
      })
    }
    console.log(plans)
    const statusOptions = [
      { label: 'Enabled', value: 'true' },
      { label: 'Disabled', value: 'false' }
    ]

    const planStatusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Expired', value: 'expired' }
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      courtFilter,
      planStatusFilter,
      planFilter,
      statusFilter
    } = useUsersList()

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      scCourtOptions,
      courtOptions,
      districtOptions,
      tribunalOptions,
      statusOptions,
      planStatusOptions,
      planOptions,

      // Extra Filters
      planStatusFilter,
      planFilter,
      courtFilter,
      statusFilter
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
