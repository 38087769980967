<template>
  <!-- Table Container Card -->
  <div>
    <!-- Table Top -->
    <b-row class="m-1">
      <!-- Per Page -->
      <b-col
        cols="4"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <h5>Cause List</h5>
      </b-col>

      <!-- Search -->
      <b-col cols="8" md="6">
        <v-select
          v-model="statusFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusOptions"
          class="invoice-filter-select"
          placeholder="Select Status"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-col>
    </b-row>
    <b-overlay
      :show="$store.state.causelistStore.causelistLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <b-card
        class="mx-0 p-0 rounded-0"
        v-if="
          !$store.state.causelistStore.causelists.length &&
          !$store.state.causelistStore.causelistLoading
        "
      >
        <p class="text-center mt-5">Record Not Found!</p>
      </b-card>
      <archived-causelist-list
        v-else
        class="mb-2"
        :userId="uid"
      ></archived-causelist-list>
      <b-table
        ref="refInvoiceListTable"
        :items="fetchInvoices"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        v-show="false"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
      </b-table>
    </b-overlay>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'
import ArchivedCauselistList from './components/ArchivedCauselistList.vue'

// import store from '@/store'
import useCauseList from './useCauseList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BOverlay,
    ArchivedCauselistList,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      uid: this.id
    }
  },
  methods: {

    getNextDate({ dates }) {
      var date = '--'
      if (Array.isArray(dates)) {
        if (dates.length > 0) {
          var dd = []
          for (let i = 0; i < dates.length; i++) {
            dd.push(moment(dates[i]).format('Do, MMMM YYYY'))
          }
          date = dd.join(', ')
        }
      } else {
        date = dates
      }
      return date
    }
  },
  setup(props) {
    const statusOptions = ['Upcoming', 'Archived']

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData
    } = useCauseList({ id: props.id })

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      statusOptions,

      refetchData,
      formatDate,

      avatarText
    }
  }
}
</script>
