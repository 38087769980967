<template>
  <section
    v-if="data.petitioner_advocates.length || data.respondent_advocates.length"
  >
    <h3 class="pb-1">Petitioner/Respondent and their Advocate(s)</h3>
    <b-row>
      <b-col class="col-12 col-md-6 pb-1">
        <b-card no-body>
          <b-row class="justify-content-between px-1">
            <b-card-title class="pl-1 pt-1">Petitioners</b-card-title>
            <div class="pr-1 pt-1" v-if="data.petitioner_advocates.length > 1">
              <b-button
                @click="togglePetitionersLimit"
                class="btn btn-sm text-primary"
                variant="light"
                >{{ isPetitionersExpanded ? 'Less' : 'More' }}</b-button
              >
            </div>
          </b-row>
          <b-list-group-item
            v-for="text in data.petitioner_advocates.slice(
              0,
              sliceLimitPetitioners
            )"
            v-bind:data="text"
            v-bind:key="text"
            >{{ text }}</b-list-group-item
          >
        </b-card>
      </b-col>
      <b-col class="col-12 col-md-6 pb-1">
        <b-card no-body>
          <b-row class="justify-content-between px-1">
            <b-card-title class="pl-1 pt-1">Respondents</b-card-title>
            <div class="pr-1 pt-1" v-if="data.respondent_advocates.length > 1">
              <b-button
                @click="toggleRespondentsLimit"
                variant="light"
                class="btn btn-sm text-primary"
                >{{ isRespondentsExpanded ? 'Less' : 'More' }}</b-button
              >
            </div>
          </b-row>
          <b-list-group-item
            v-for="text in data.respondent_advocates.slice(
              0,
              sliceLimitRespondents
            )"
            v-bind:data="text"
            v-bind:key="text"
            >{{ text }}</b-list-group-item
          >
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BListGroup,
  BListGroupItem,
  BCardTitle
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BButton,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BListGroup,
    BListGroupItem
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter,
      sliceLimitPetitioners: 1,
      isPetitionersExpanded: false,
      sliceLimitRespondents: 1,
      isRespondentsExpanded: false
    }
  },
  methods: {
    togglePetitionersLimit() {
      if (this.isPetitionersExpanded) {
        this.sliceLimitPetitioners = 1
        this.isPetitionersExpanded = false
      } else {
        this.sliceLimitPetitioners = 100
        this.isPetitionersExpanded = true
      }
    },
    toggleRespondentsLimit() {
      if (this.isRespondentsExpanded) {
        this.sliceLimitRespondents = 1
        this.isRespondentsExpanded = false
      } else {
        this.sliceLimitRespondents = 100
        this.isRespondentsExpanded = true
      }
    }
  }
}
</script>
