<template>
  <b-card title="User Matters" class="m-0 p-0" no-body>
    <user-cause-list :id="id" />
  </b-card>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar } from 'bootstrap-vue'
import UserCauseList from './causelist-list/UserCauseList.vue'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    UserCauseList
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
        id: this.userId
    }
  }
}
</script>

<style></style>
