<template>
  <b-overlay
    :show="$store.state.matterStore.exportJudgmentLoading"
    rounded="sm"
    variant="transparent"
    opacity="0.5"
    blur="2px"
  >
    <div>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="danger"
        v-b-tooltip.hover
        title="Export PDF"
        :disabled="!id"
        class="btn-sm"
        @click="exportAllJudgment"
      >
        Export All Judgments
      </b-button>
    </div>
  </b-overlay>
</template>

<script>
import { BButton, VBTooltip, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import {Blob} from 'node-blob'

// import axios from 'axios'

export default {
  components: {
    BButton,
    BOverlay
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  props: {
    matter_id: {
      type: String,
      default: null
    },
    userId: {
      default: null
    }
  },
  data() {
    return {
      id: this.matter_id,
      user_id: this.userId
    }
  },
  methods: {
    exportAllJudgment() {
      this.$store
        .dispatch('matterStore/exportAllJudgments', { id: this.id, user_id: this.user_id })
        .then((response) => {
          console.log('Judgments Export --> ', response)
          if (response) {
            var link = document.createElement('a')
            link.href = response.url.replace("http://s3.mymunshi.live", "https://app.mymunshi.in/exports")
            link.target = '_blank'
            link.download = `judgments_${this.id}.pdf`
            link.click()
            window.URL.revokeObjectURL(link)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
