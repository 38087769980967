import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    // courtsData: {}
    settingLoading: false,
    pushLoading: false,
    planUsers: []
  },
  getters: {
    // getAllMatters: (state) => {
    //   return state.allMatters
    // }
  },
  mutations: {
    changeSettingLoading(state, val) {
      state.settingLoading = val
    },
    changePushLoading(state, val) {
      state.pushLoading = val
    },
    changePlanUsers(state, val) {
      state.planUsers = val
    }
  },
  actions: {
    async updateUser({commit}, {ctx, payload}) {
      commit('changeSettingLoading', true)
      await axiosIns
        .post(defaultConfig.userUpdate, payload)
        .then((response) => {
          commit('changeSettingLoading', false)
          if (response.data.success) {
            localStorage.setItem(
              'userData',
              JSON.stringify(response.data.userData)
            )
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success!`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Updated Successfully!`
              }
            })
          }
          else {
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Failed!`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: `Enter a valid fields!`
              }
            })
          }
        })
        .catch((error) => {
          commit('changeSettingLoading', false)
          console.log(`Error ${error}`)
          ctx.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Failed!`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: `Failed to Update!`
            }
          })
        })
    },
    fetchPlanUsers({ commit }, ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getPlanUsersAnalytics)
          .then((response) => {
            if (response.data.success) {
              commit('changePlanUsers', response.data.data)
            }
          })
          .catch(console.log)
      })
    },
    async sendGeneralPush({commit}, {ctx, payload}) {
      commit('changePushLoading', true)
      await axiosIns
        .post(defaultConfig.sendGeneralPush, payload)
        .then((response) => {
          commit('changePushLoading', false)
          if (response.data.success) {
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success!`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Sent Successfully!`
              }
            })
          } else {
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Failed!`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: `Failed to Send!`
              }
            })
          }
        })
        .catch((error) => {
          commit('changePushLoading', false)
          console.log(`Error ${error}`)
          ctx.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error!`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: `Failed to Send!`
            }
          })
        })
    },
  }
}
