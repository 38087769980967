<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Filters</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="6" class="mb-md-0 mb-2">
          <label>By Court</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="courtFilter"
            :options="courtOptions"
            label="label"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:courtFilter', val)"
          />
        </b-col>
        <b-col cols="6" class="mb-md-0 mb-2">
          <label>By Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
        <b-col cols="6" class="mb-md-0 mb-2">
          <label>By Plan</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="planFilter"
            :options="planOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:planFilter', val)"
          />
        </b-col>
        <b-col cols="6" class="mb-md-0 mb-2">
          <label>By Plan Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="planStatusFilter"
            :options="planStatusOptions"
            class="w-100"
            :reduce="(val) => val.value"
            @input="(val) => $emit('update:planStatusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect
  },
  props: {
    courtFilter: {
      type: [String, null],
      default: null
    },
    statusFilter: {
      type: [String, null],
      default: null
    },
    planStatusFilter: {
      type: [String, null],
      default: null
    },
    planFilter: {
      type: [String, null],
      default: null
    },
    courtOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    },
    planStatusOptions: {
      type: Array,
      required: true
    },
    planOptions: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
