<template>
  <div>
    <!-- Basic Details -->
    <district-basic-details
      :matter="$store.state.matterStore.currentmatter"
    ></district-basic-details>
    <!-- Case Status -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'case_details'
        )
      "
    >
      <district-case-status
        :matter="$store.state.matterStore.currentmatter"
        :detail="$store.state.matterStore.currentmatter.case_details"
      ></district-case-status>
    </div>
    <!-- Acts -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes('acts')
      "
    >
      <act-details
        :matter="$store.state.matterStore.currentmatter"
      ></act-details>
    </div>
    <!-- Linked Matters -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'linked_matters'
        )
      "
    >
      <linked-matters
        v-if="$store.state.matterStore.currentmatter.linked_matters"
        :matter="$store.state.matterStore.currentmatter"
      ></linked-matters>
    </div>
    <!-- FIR Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'crime_dic'
        )
      "
    >
      <f-i-r-details
        :matter="$store.state.matterStore.currentmatter"
        :detail="$store.state.matterStore.currentmatter.case_details"
      ></f-i-r-details>
    </div>
    <!-- Peitioner & Respondent -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'petitioner_advocates'
        ) ||
        Object.keys($store.state.matterStore.currentmatter).includes(
          'respondent_advocates'
        )
      "
    >
      <petitioner-respondents
        :matter="$store.state.matterStore.currentmatter"
      ></petitioner-respondents>
    </div>
    <!-- Listening History -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'listing_history'
        )
      "
    >
      <listing-details
        :matter="$store.state.matterStore.currentmatter"
      ></listing-details>
    </div>
    <!-- Transfer History -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'transfer_history'
        )
      "
    >
      <transfer-history
        :matter="$store.state.matterStore.currentmatter"
      ></transfer-history>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// Section Imports
import DistrictBasicDetails from './components/DistrictBasicDetails.vue'
import DistrictCaseStatus from './components/DistrictCaseStatus.vue'
import ListingDetails from './components/ListingDetails.vue'
import PetitionerRespondents from './components/PetitionerRespondents.vue'
import LinkedMatters from './components/LinkedMatters.vue'
import ActDetails from './components/ActDetails.vue'
import TransferHistory from './components/TransferHistory.vue'
import FIRDetails from './components/FIRDetails.vue'

export default {
  components: {
    BRow,
    BCol,
    // Basic Details
    DistrictBasicDetails,
    DistrictCaseStatus,
    ListingDetails,
    PetitionerRespondents,
    LinkedMatters,
    ActDetails,
    TransferHistory,
    FIRDetails
  }
}
</script>
