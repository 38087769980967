<template>
  <div>
    <!-- Basic Details -->
    <basic-details
      :matter="$store.state.matterStore.currentmatter"
    ></basic-details>
    <!-- Case Status -->
    <tribunals-status
      :matter="$store.state.matterStore.currentmatter"
    ></tribunals-status>
    <!-- Linked Matters -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'linked_matters'
        )
      "
    >
      <linked-matters
        v-if="$store.state.matterStore.currentmatter.linked_matters"
        :matter="$store.state.matterStore.currentmatter"
      ></linked-matters>
    </div>
    <!-- Peitioner & Respondent -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'petitioner_advocates'
        ) ||
        Object.keys($store.state.matterStore.currentmatter).includes(
          'respondent_advocates'
        )
      "
    >
      <petitioner-respondents
        :matter="$store.state.matterStore.currentmatter"
      ></petitioner-respondents>
    </div>
    <!-- Reference Case Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'reference_case_details'
        )
      "
    >
      <reference-case-details
        :matter="$store.state.matterStore.currentmatter"
      ></reference-case-details>
    </div>
    <!-- Document Filing Details -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'document_details'
        )
      "
    >
      <document-filing-details
        :matter="$store.state.matterStore.currentmatter"
      ></document-filing-details>
    </div>
    <!-- Listening History -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'case_proceeding'
        )
      "
    >
      <case-proceeding
        :matter="$store.state.matterStore.currentmatter"
      ></case-proceeding>
    </div>
    <!-- <b-row> -->
      <!-- Final Order -->
      <!-- <b-col
        class="col-12 col-md-6 mb-1"
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes(
            'final_orders'
          )
        "
      >
        <tribunal-orders
          :matter="$store.state.matterStore.currentmatter"
          :title="'Final Orders'"
        ></tribunal-orders>
      </b-col> -->
      <!-- Orders -->
      <!-- <b-col
        class="col-12 col-md-6 mb-1"
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes('orders')
        "
      >
        <tribunal-orders
          :matter="$store.state.matterStore.currentmatter"
          :title="'Orders'"
        ></tribunal-orders>
      </b-col> -->
    <!-- </b-row> -->
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// Section Imports
import BasicDetails from './components/BasicDetails.vue'
import TribunalsStatus from './components/TribunalsStatus.vue'
import DocumentFilingDetails from './components/DocumentFilingDetails.vue'
import CaseProceeding from './components/CaseProceeding.vue'
import PetitionerRespondents from './components/PetitionerRespondents.vue'
import ReferenceCaseDetails from './components/ReferenceCaseDetails.vue'
import LinkedMatters from './components/LinkedMatters.vue'
import TribunalOrders from './components/TribunalOrders.vue'

export default {
  components: {
    BRow,
    BCol,
    // Basic Details
    BasicDetails,
    DocumentFilingDetails,
    TribunalsStatus,
    CaseProceeding,
    PetitionerRespondents,
    ReferenceCaseDetails,
    LinkedMatters,
    TribunalOrders
  }
}
</script>
