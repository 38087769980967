import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import { of } from 'rxjs'
import moment from 'moment'
import { groupBy, map, mergeMap, reduce, toArray } from 'rxjs/operators'

export default {
  namespaced: true,
  state: {
    causelists: [],
    causelistLoading: false,
    exportCauseListLoading: false,
    currentExportDate: ''
  },
  getters: {},
  mutations: {
    setArchivedCauselist(state, { payload, type }) {
      let pl;
      if (type === 'Archived') {
        pl = payload.sort(function (a, b) {
          return new Date(b.datetime) - new Date(a.datetime)
        })
      } else {
        pl = payload.sort(function (a, b) {
          return new Date(a.hearing_dates) - new Date(b.hearing_dates)
        })
      }
      of(...pl)
        .pipe(
          groupBy((p) => {
            return moment(
              type == 'Archived' ? p.datetime : p.hearing_dates
            ).format('DD-MM-YYYY')
          }),
          mergeMap((group$) =>
            group$.pipe(reduce((acc, cur) => [...acc, cur], [`${group$.key}`]))
          ),
          map((arr) => ({ date: arr[0], data: arr.slice(1) })),
          toArray()
        )
        .subscribe((p) => (state.causelists = p))
    },
    changeCauselistLoading(state, val) {
      state.causelistLoading = val
    },
    changeExportCauseListLoading(state, val) {
      state.exportCauseListLoading = val
    },
    changeCurrentCauseListLoading(state, date) {
      state.currentExportDate = date
    }
  },
  actions: {
    // Get Matter
    fetchCauseList({ commit }, { ctx, queryParams }) {
      commit('changeCauselistLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getCauselistEndPoint(queryParams))
          .then((response) => {
            commit('changeCauselistLoading', false)
            if (response.data.success) {
              console.log('Archiveds', response.data.data)
              commit('setArchivedCauselist', {
                payload: response.data.data,
                type: queryParams.type
              })
            }
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeCauselistLoading', false)
            reject(error)
          })
      })
    },
    exportCauseListByDate({ commit }, { ctx, date, user_id }) {
      commit('changeExportCauseListLoading', true)
      commit('changeCurrentCauseListLoading', date)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.hearingExportByDateEndPoint({ date, user_id }))
          .then((response) => {
            commit('changeExportCauseListLoading', false)
            commit('changeCurrentCauseListLoading', '')
            console.log({ FetchingMatterData: JSON.stringify(response.data) })
            if (response.data.success) {
              return resolve(response.data)
            }
            return null
          })
          .catch((error) => {
            commit('changeExportCauseListLoading', false)
            commit('changeCurrentCauseListLoading', '')
            reject(error)
            return null
          })
      })
    }
  }
}
