<template>
  <b-card title="User Plans">
    <b-row>
      <b-col md="6">
        <b-card
            v-if="user.plan !== null"
            border-variant="success"
            class="m-0 mb-md-0 mb-1"
            no-body
            header-bg-variant="success"
          >
            <template #header>
              <b-col>
                <b-row class="justify-content-between">
                  <b-col class="p-0 m-0">
                    <h5 class="font-weight-bolder m-0 text-white">
                      Current Plan
                    </h5>
                  </b-col>
                  <b-col>
                    <b-row class="justify-content-end">
                      <b-badge variant="primary" class="badge-round">
                        <feather-icon
                          :id="`plan-type`"
                          icon="CheckCircleIcon"
                          size="16"
                          class="mr-25 cursor-pointer"
                        />
                        <span>Premium</span>
                      </b-badge>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </template>
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Plan Name</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ user.plan.name }}</p>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Plan Type</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ user.plan.is_free ? 'Free' : 'Paid' }}</p>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Plan Duration</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ user.plan.period_days }} Days</p>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Exp. Date</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ getDate(user.expires_at) }}</p>
              </b-col>
            </b-row>
            <hr style="margin: 0" />
            <b-row class="p-75">
              <b-col class="col-6">
                <h6 class="m-0">Days Left</h6>
              </b-col>
              <b-col class="col-6 text-right">
                <p class="m-0">{{ getFromNowDate(user.expires_at) }}</p>
              </b-col>
            </b-row>
          </b-card>
          <b-card title="Change Expiry Date" class="mt-2">
          <!-- Plan -->
          <b-form-group label="Expiry Date" label-for="date">
            <b-form-datepicker
              v-model="planData.expires_at"
              label="name"
              input-id="date"
            >
            </b-form-datepicker>
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="px-1"
            @click="onDateChange"
          >
            Update
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6" class="pt-2 pt-md-0">
        <b-card title="Upgrade Plan" class="m-0 mb-md-0">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <!-- Form -->
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <!-- Plan -->
              <!-- Select Plan -->
              <validation-provider
                #default="validationContext"
                name="Select Plan"
                rules="required"
              >
                <b-form-group
                  label="Select Plan"
                  label-for="plan"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="planData.plan_reference_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="$store.state.planStore.plansUpgradeList"
                    :reduce="(val) => val.reference_id"
                    :clearable="false"
                    label="name"
                    input-id="plan"
                  >
                    <template slot="option" slot-scope="option">
                      <span></span>
                      {{ option.name }} - ({{ option.period_days }} days) (₹{{
                        option.price
                      }})
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.name }} - ({{ option.period_days }} days) (₹{{
                        option.price
                      }})
                    </template>
                  </v-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Payment Mode -->
              <validation-provider
                #default="validationContext"
                name="Payment Mode"
                rules="required"
              >
                <b-form-group
                  label="Payment Mode"
                  label-for="mode"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="planData.payment_mode"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['Cash', 'UPI', 'Net Banking', 'Payment Gateway', 'Cheque']"
                    :reduce="(val) => val"
                    :clearable="false"
                    label="name"
                    input-id="mode"
                  >
                  </v-select>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                class="px-1"
              >
                Upgrade
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card title="User Comment" class="mt-2">
          <!-- Plan -->
          <b-form-group label="User Comment" label-for="date">
            <b-form-textarea
              id="textarea-default"
              v-model="comment"
              placeholder="User Comment"
              rows="3"
            />
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="px-1"
            :disabled="!comment"
            @click="updateComment"
          >
            Update
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BMedia,
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormInvalidFeedback,
  BForm,
  BFormGroup,
  BFormDatepicker,
  BFormTextarea
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import moment from 'moment'
import store from '@/store'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BRow,
    BCol,
    BBadge,
    BButton,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BForm,
    BFormGroup,
    BFormDatepicker,
    BFormTextarea
  },
  directives: {
    Ripple
  },
  props: {
    uData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      user: this.uData,
      comment: this.uData.comment,
      required
    }
  },
  methods: {
    getDate(date) {
      return moment(date).format('ll')
    },
    getFromNowDate(date) {
      return moment(date).endOf('day').fromNow()
    }
  },
  setup(props, context) {
    const blankData = {
      plan_reference_id: null,
      payment_mode: null,
      user_id: props.uData._id,
      expires_at: props.uData.expires_at
    }

    const planData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetplanData = () => {
      planData.value = JSON.parse(JSON.stringify(blankData))
    }

    function updateComment() {
      store.dispatch('userStore/updateUser', { 
        payload: { user_id: this.user._id, comment: this.comment }})
        .then((response) => {
          if (response != null) {
            
          }
        })
    }

    function onSubmit() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You really want to upgrade a plan to this user',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, upgrade it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          store
            .dispatch('userStore/applyPlan', { data: planData.value })
            .then((response) => {
              if (response != null) {
                this.$swal({
                  icon: 'success',
                  title: 'Upgraded',
                  text: 'Plan Upgrade Successfully!',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
                context.emit('refetch-data')
              }
            })
        }
      })
    }

    function onDateChange() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You really want to change expiry date of current plan.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          const payload = {
            user_id: planData.value.user_id,
            expires_at: planData.value.expires_at
          }
          console.log(payload)
          store
            .dispatch('userStore/updateUser', { payload })
            .then((response) => {
              if (response != null) {
                this.$swal({
                  icon: 'success',
                  title: 'Changed',
                  text: 'Plan Expiry Date Changed!',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
                context.emit('refetch-data')
              }
            })
        }
      })
    }

    const { refFormObserver, refFormObserver1, getValidationState, resetForm } =
      formValidation(resetplanData)

    return {
      planData,
      onSubmit,
      onDateChange,

      refFormObserver,
      refFormObserver1,
      getValidationState,
      updateComment,
      resetForm
    }
  }
}
</script>

<style></style>
