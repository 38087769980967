<template>
  <div>
    <template v-if="userId !== null">
      <!-- First Row -->
      <user-view-user-info-card :userId="userId" />

      <div>
        <b-tabs pills>
          <!-- <b-tab active lazy>
            <template #title>
              <feather-icon icon="TrelloIcon" />
              <span>Analytics</span>
            </template>
            <user-view-analytics :userId="userId"></user-view-analytics>
          </b-tab> -->
          <b-tab active lazy
            >
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Cause List</span>
            </template>
            <user-view-cause-list :userId="userId"></user-view-cause-list
          ></b-tab>
          <b-tab lazy
            >
            <template #title>
              <feather-icon icon="ServerIcon" />
              <span>Matters</span>
            </template>
            <user-view-matter :userId="userId"></user-view-matter
          ></b-tab>
          <b-tab lazy
            >
            <template #title>
              <feather-icon icon="AwardIcon" />
              <span>Plans</span>
            </template>
            <user-view-plan :userId="userId"></user-view-plan
          ></b-tab>
          <b-tab lazy
            >
            <template #title>
              <feather-icon icon="LayersIcon" />
              <span>More</span>
            </template>
            <user-view-more :userId="userId"></user-view-more></b-tab
          >
        </b-tabs>
      </div>
    </template>
  </div>
</template>

<script>
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BCard,
  BCardText,
  BTab,
  BTabs
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import UserViewUserInfoCard from './components/UserViewUserInfoCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import UserViewAnalytics from './user-analytics/UserViewAnalytics.vue'
import UserViewCauseList from './user-causelist/UserViewCauseList.vue'
import UserViewMatter from './user-matter/UserViewMatter.vue'
import UserViewPlan from './user-plans/UserViewPlan.vue'
import UserViewMore from './user-more/UserViewMore.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BCardText,
    BTab,
    BTabs,
    // Local Components
    UserViewUserInfoCard,
    UserViewAnalytics,
    UserViewCauseList,
    UserViewMatter,
    UserViewPlan,
    UserViewMore,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,

    InvoiceList
  },
  beforeMount() {
    //   Plans
    this.$store.dispatch('planStore/fetchPlans')
  },
  setup() {
    const userId = ref(null)

    userId.value = router.currentRoute.params.id

    return {
      userId
    }
  }
}
</script>

<style></style>
