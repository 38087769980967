<template>
  <b-card no-body>
    <b-card-header>
      <h4 class="mb-0">Goal Overview</h4>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="220"
      :options="goalOverviewRadialBar.chartOptions"
      :series="goalOverviewRadialBar.series"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="4"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0"> TOTAL CASES </b-card-text>
        <div v-if="$store.state.matterStore.trackerCount !== null">
          <h3
            class="font-weight-bolder mb-50"
            v-if="$store.state.matterStore.trackerCount.length >= 2"
          >
            {{ $store.state.matterStore.trackerCount[0].count + $store.state.matterStore.trackerCount[1].count }}
          </h3>
        </div>
      </b-col>

      <b-col
        cols="4"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text
          class="text-muted mb-0"
          v-if="$store.state.matterStore.trackerCount !== null"
        >
          {{ $store.state.matterStore.trackerCount[0].case_status }}
        </b-card-text>
        <h3
          class="font-weight-bolder mb-50"
          v-if="$store.state.matterStore.trackerCount !== null"
        >
          {{ $store.state.matterStore.trackerCount[0].count }}
        </h3>
      </b-col>

      <b-col
        cols="4"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text
          class="text-muted mb-0"
          v-if="$store.state.matterStore.trackerCount !== null"
        >
          {{ $store.state.matterStore.trackerCount[1].case_status }}
        </b-card-text>
        <h3
          class="font-weight-bolder mb-50"
          v-if="$store.state.matterStore.trackerCount !== null"
        >
          {{ $store.state.matterStore.trackerCount[1].count }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol
  },
  data() {
    return {
      goal_overview: {},
      goalOverviewRadialBar: {
        series: [0],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1
            }
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%'
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%'
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '2rem',
                },
              },
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'round'
          },
          grid: {
            padding: {
              bottom: 30
            }
          },
          labels: ['Closed Cases'],
        }
      }
    }
  },
  created() {
    this.$store.dispatch('matterStore/fetchTrackerCount').then((response) => {
        if(this.$store.state.matterStore.trackerCount !== null){
          if(this.$store.state.matterStore.trackerCount[1].case_status === "PENDING"){
            let perc = this.$store.state.matterStore.trackerCount[0].count*100 / (this.$store.state.matterStore.trackerCount[0].count + this.$store.state.matterStore.trackerCount[1].count)
            console.log("PERC -->", perc)
            this.goalOverviewRadialBar.series = [parseInt(perc)]
          }else{
            let perc = this.$store.state.matterStore.trackerCount[1].count*100 / (this.$store.state.matterStore.trackerCount[0].count + this.$store.state.matterStore.trackerCount[1].count)
            console.log("PERC -->", perc)
            this.goalOverviewRadialBar.series = [parseInt(perc)]
          }
            
        }
    })
  }
}
</script>
