var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"matter-preview-wrapper"},[(
      _vm.$store.state.matterStore.currentmatter &&
      !_vm.$store.state.matterStore.matterPreviewLoading
    )?_c('b-row',{staticClass:"matter-preview"},[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',[_c('b-row',{staticClass:"justify-content-end px-1 d-md-none"},[_c('b-row',[_c('matter-order-judgment',{staticClass:"mb-1 mr-1",attrs:{"data":_vm.$store.state.matterStore.currentmatter,"userId":_vm.user_id,"type":'sm'}})],1)],1),(
            _vm.$store.state.matterStore.currentmatter.hc_name.includes('ec-')
          )?_c('district-details'):_vm._e(),(
            _vm.$store.state.matterStore.currentmatter.hc_name.includes('hc-')
          )?_c('h-c-details'):_vm._e(),(
            _vm.$store.state.matterStore.currentmatter.hc_name.includes(
              'tribunal-'
            )
          )?_c('tribunal-details'):_vm._e()],1)],1),_c('b-col',{staticClass:"matter-actions",attrs:{"cols":"12","md":"4","xl":"3"}},[_c('b-card',[_c('matter-order-judgment',{attrs:{"data":_vm.$store.state.matterStore.currentmatter,"userId":_vm.user_id,"type":'lg'}})],1)],1)],1):_vm._e(),(
      !_vm.$store.state.matterStore.currentmatter &&
      _vm.$store.state.matterStore.matterPreviewLoading
    )?_c('b-card',{staticClass:"text-center mt-2"},[_c('div',{staticClass:"d-flex justify-content-center my-2"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)]):_vm._e(),(
      !_vm.$store.state.matterStore.currentmatter &&
      !_vm.$store.state.matterStore.matterPreviewLoading
    )?_c('b-card',{staticClass:"text-center mt-2"},[_vm._v(" Please Try Again! ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }