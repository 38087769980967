<template>
  <div v-if="data.lower_court != null">
    <section v-if="Object.keys(data.lower_court).length">
      <h3 class="pb-1">Lower Court</h3>
      <b-card no-body class="py-1">
        <!-- Case Number -->
        <b-row class="px-1">
          <b-col class="col-5">
            <h6>Case No. and Year</h6>
          </b-col>
          <b-col class="col-7">
            <p
              class="m-0"
              v-if="Object.keys(data.lower_court).includes('case_number')"
            >
              {{ data.lower_court.case_number }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
        </b-row>

        <hr />
        <!-- Case Decision Date -->
        <b-row class="px-1">
          <b-col class="col-5">
            <h6>Case Decision Date</h6>
          </b-col>
          <b-col class="col-7">
            <p
              class="m-0"
              v-if="
                Object.keys(data.lower_court).includes('case_decision_date')
              "
            >
              {{ data.lower_court.case_decision_date }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
        </b-row>
        <hr />
        <!-- District -->
        <b-row class="px-1">
          <b-col class="col-5">
            <h6>District</h6>
          </b-col>
          <b-col class="col-7">
            <p
              class="m-0"
              v-if="Object.keys(data.lower_court).includes('district')"
            >
              {{ data.lower_court.district }}
            </p>
            <p class="m-0" v-else>--</p>
          </b-col>
        </b-row>
      </b-card>
    </section>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  }
}
</script>
