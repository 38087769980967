<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Export</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="4" class="mb-md-0 mb-2">
          <b-form-group label="Start Date" label-for="start-datepicker">
            <b-form-datepicker id="start-datepicker" v-model="startDate" />
          </b-form-group>
        </b-col>
        <b-col cols="4" class="mb-md-0 mb-2">
          <b-form-group label="End Date" label-for="end-datepicker">
            <b-form-datepicker id="end-datepicker" v-model="endDate" />
          </b-form-group>
        </b-col>
        <b-col cols="2" class="mb-md-0 mb-2">
          <b-button
            variant="primary"
            class="mt-2"
            :disabled="!startDate || !endDate"
            @click="exportRangeUsers"
          >
            <span class="text-nowrap">Export</span>
          </b-button>
        </b-col>
        <b-col cols="2" class="mb-md-0 mb-2">
          <b-row>
            <b-button variant="success" class="mt-2" @click="exportAllUsers">
              <span class="text-nowrap">Export All</span>
            </b-button>
          </b-row>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BFormGroup
} from 'bootstrap-vue'
import defaultConfig from '@/configs/defaultConfig'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormDatepicker,
    BFormGroup
  },
  data() {
    return {
      startDate: null,
      endDate: null
    }
  },
  methods: {
    exportAllUsers() {
      const url = defaultConfig.baseUrl + defaultConfig.exportAllUserEndPoint
      window.open(url, '_blank')
    },
    exportRangeUsers() {
      const url =
        defaultConfig.baseUrl +
        defaultConfig.exportUserEndPoint({
          startDate: this.startDate,
          endDate: this.endDate
        })
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
