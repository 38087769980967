import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    // mattersData: null,
    activeMatters: [],
    closedMatters: [],
    allMatters: [],
    judgmentList: [],
    notesList: [],
    docsList: [],
    notesLoading: false,
    judgmentLoading: false,
    docsLoading: false,
    newMatterLoading: false,
    mattersLoading: false,
    matterPreviewLoading: false,
    matterRefreshLoading: false,
    currentmatter: null,
    trackerCount: null,
    trackerCountYearly: null,
    exportJudgmentLoading: false,
    clientDetailLoading: false
  },
  getters: {},
  mutations: {
    clearCurrentMatter(state) {
      state.currentmatter = null
    },
    setTrackerCountData(state, payload) {
      state.trackerCount = payload.data
    },
    setTrackerCountYearlyData(state, payload) {
      state.trackerCountYearly = payload.data
    },
    setCurrentMatterData(state, payload) {
      state.currentmatter = payload
    },
    changeClientLoading(state, val) {
      state.clientDetailLoading = val
    },
    changeMatterPreviewLoading(state, val) {
      state.matterPreviewLoading = val
    },
    changeMatterRefreshLoading(state, val) {
      state.matterRefreshLoading = val
    },
    setJudgmentData(state, payload) {
      if (payload.success) {
        state.judgmentList = payload.data
      }
      console.log({ JudgmentList: JSON.stringify(state.judgmentList) })
    },
    changeJudgmentLoading(state, val) {
      state.judgmentLoading = val
    },
    changeExportJudgmentLoading(state, val) {
      state.exportJudgmentLoading = val
    },
    clearJudgmentData(state) {
      state.judgmentList = []
    },
    changeMattersLoading(state, val) {
      state.mattersLoading = val
    },
    changeNewMatterLoading(state, val) {
      state.newMatterLoading = val
    },
    changeNotesLoading(state, val) {
      state.notesLoading = val
    },
    setNotesData(state, payload) {
      if (payload.success) {
        state.notesList = payload.data.reverse()
      }
      console.log({ NotesList: JSON.stringify(state.notesList) })
    },
    clearNotesData(state) {
      state.notesList = []
    },
    setDocsData(state, payload) {
      if (payload.success) {
        state.docsList = payload.data.reverse()
      }
      console.log({ DocsList: JSON.stringify(state.docsList) })
    },
    changeDocsLoading(state, val) {
      state.docsLoading = val
    },
    clearDocsData(state) {
      state.docsList = []
    }
  },
  actions: {
    // Matters ------------------------------->>>>>>>>>

    // Get Matter
    fetchMatters({ commit }, { ctx, queryParams }) {
      console.log('Setch Matters -->', queryParams)
      commit('changeMattersLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getMattersEndPoint, { params: queryParams })
          .then((response) => {
            commit('changeMattersLoading', false)
            console.log({
              FetchingMatterData: JSON.stringify(response.data.meta.total)
            })
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeMattersLoading', false)
            reject(error)
          })
      })
    },
    // Get Matter
    fetchMatterById({ commit }, { ctx, id }) {
      console.log('Matter Id -->', id)
      commit('clearCurrentMatter')
      commit('changeMatterPreviewLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getMatterByIdEndPoint({ id: id }))
          .then((response) => {
            console.log({
              'Matter-->': JSON.stringify(response.data)
            })
            if (response.data.success) {
              commit('setCurrentMatterData', response.data.data[0])
            }
            commit('changeMatterPreviewLoading', false)
            return resolve(response.data.data[0])
          })
          .catch((error) => {
            commit('changeMatterPreviewLoading', false)
            reject(error)
          })
      })
    },
    // Find Matters
    findMatter({ commit }, { ctx, payload }) {
      console.log('Matter Find : ', payload)
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.findMatterEndPoint, payload)
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              FetchingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewMatterLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },
    // Save Matters
    updatePublicData({ commit }, { ctx, matter_id, payload }) {
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(
            defaultConfig.updateMatterPublicDataEndPoint({ id: matter_id }),
            payload
          )
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Data saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                ctx.$bvModal.hide('modal-create-client')
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeClientLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Save Matters
    saveMatter({ commit }, { ctx, payload }) {
      commit('changeClientLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .put(defaultConfig.saveMatterEndPoint, payload)
          .then((response) => {
            commit('changeClientLoading', false)
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Matter saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                ctx.$bvModal.hide('modal-create-matter')
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewMatterLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Delete Matter
    deleteMatter({ commit, dispatch }, { ctx, _id }) {
      console.log('Matter Id: ', _id)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.deleteMatterEndPoint({ id: _id }))
          .then((response) => {
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Matter deleted successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
            return null
          })
      })
    },

    // Find Matters
    refreshMatter({ commit, dispatch }, { ctx, id }) {
      console.log('Matter Refresh : ', id)
      commit('changeMatterRefreshLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.findMatterEndPoint, {
            matter_id: id,
            refresh: true
          })
          .then((response) => {
            commit('changeMatterRefreshLoading', false)
            console.log({
              ReFetchingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                dispatch('fetchMatter', { id })
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Unable to Connect. Please Try Again!',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeMatterRefreshLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Matter Count ------------------------------->>>>>>>>>

    // Get Tracker Count
    fetchTrackerCount(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getTrackerCount({ type: 'case_status' }))
          .then((response) => {
            if (response.data.success) {
              ctx.commit('setTrackerCountData', response.data)
            }
            return resolve(response.data)
          })
          .catch((error) => {
            reject(error)
            return null
          })
      })
    },

    // Get Tracker Count Yearly
    fetchTrackerCountYearly(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getTrackerCount({ type: 'case_year' }))
          .then((response) => {
            if (response.data.success) {
              ctx.commit('setTrackerCountYearlyData', response.data)
            }
            return resolve(response.data)
          })
          .catch((error) => {
            reject(error)
            return null
          })
      })
    },

    // Get Judgments ------------------------------->>>>>>>>>

    fetchMatterJudgment(ctx, {matterId}) {
      console.log('matter Id --->', matterId)
      ctx.commit('changeJudgmentLoading', true)
      ctx.commit('clearJudgmentData')
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getJudgmentEndPoint + '?filters[matter_id]=' + matterId)
          .then((response) => {
            console.log({
              FetchingJudgment: JSON.stringify(response.data)
            })
            if (response.data.success) {
              ctx.commit('setJudgmentData', response.data)
            }
            ctx.commit('changeJudgmentLoading', false)
          })
          .catch((error) => {
            ctx.commit('changeJudgmentLoading', false)
            reject(error)
          })
      })
    },

    exportAllJudgments({ commit }, { ctx, id, user_id }) {
      commit('changeExportJudgmentLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.allJudgmentExportEndPoint({ id, user_id }))
          .then((response) => {
            commit('changeExportJudgmentLoading', false)
            console.log({ ExportingJudgment: JSON.stringify(response.data) })
            if (response.data.success) {
              return resolve(response.data)
            }
            return null
          })
          .catch((error) => {
            commit('changeExportJudgmentLoading', false)
            reject(error)
            return null
          })
      })
    },

    // Matter Notes ------------------------------->>>>>>>>>

    // Get Notes
    getMatterNotes(ctx, matter_id) {
      console.log('Notes Id: ', matter_id)
      ctx.commit('changeNotesLoading', true)
      ctx.commit('clearNotesData')
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getMatterNotesEndPoint({ id: matter_id }))
          .then((response) => {
            if (response.data.success) {
              ctx.commit('setNotesData', response.data)
            }
            ctx.commit('changeNotesLoading', false)
          })
          .catch((error) => {
            reject(error)
            ctx.commit('changeNotesLoading', false)
          })
      })
    },

    // Save Note
    saveNote({ commit, dispatch }, { ctx, payload }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.saveMatterNoteEndPoint, payload)
          .then((response) => {
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Note saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                dispatch('getMatterNotes', payload.matter_id)
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Delete Note
    deleteNote({ commit, dispatch }, { ctx, _id, matter_id }) {
      console.log('Notes Id: ', _id)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.deleteMatterNoteEndPoint({ id: _id }))
          .then((response) => {
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Note deleted successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                dispatch('getMatterNotes', matter_id)
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Matter Documents ------------------------------->>>>>>>>>

    // Get Docs
    getMatterDocuments(ctx, matter_id) {
      console.log('Matter Id: ', matter_id)
      ctx.commit('changeDocsLoading', true)
      ctx.commit('clearDocsData')
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getMatterDocsEndPoint({ id: matter_id }))
          .then((response) => {
            if (response.data.success) {
              ctx.commit('setDocsData', response.data)
            }
            ctx.commit('changeDocsLoading', false)
          })
          .catch((error) => {
            reject(error)
            ctx.commit('changeDocsLoading', false)
          })
      })
    },
    // Save Note
    saveDocument({ commit, dispatch }, { ctx, payload, matter_id }) {
      let data = payload
      console.log('Matter --->', matter_id)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.saveMatterDocEndPoint, data)
          .then((response) => {
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Document saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                dispatch('getMatterDocuments', matter_id)
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },
    // Delete Document
    deleteDocument({ commit, dispatch }, { ctx, _id, matter_id }) {
      console.log('Doc Id: ', _id)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.deleteMatterDocEndPoint({ id: _id }))
          .then((response) => {
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Document deleted successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                dispatch('getMatterDocuments', matter_id)
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    }
  }
}
