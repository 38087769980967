<template>
  <div>
    <!-- Basic Details -->
    <basic-details
      :matter="$store.state.matterStore.currentmatter"
    ></basic-details>
    <!-- Case Status -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'case_details'
        )
      "
    >
      <case-status
        :matter="$store.state.matterStore.currentmatter"
        :detail="$store.state.matterStore.currentmatter.case_details"
      ></case-status>
    </div>
    <!-- Category -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes('category')
      "
    >
      <category-details
        :matter="$store.state.matterStore.currentmatter"
      ></category-details>
    </div>
    <!-- Linked Matters -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'linked_matters'
        )
      "
    >
      <linked-matters
        v-if="$store.state.matterStore.currentmatter.linked_matters"
        :matter="$store.state.matterStore.currentmatter"
      ></linked-matters>
    </div>
    <!-- Peitioner & Respondent -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'petitioner_advocates'
        ) ||
        Object.keys($store.state.matterStore.currentmatter).includes(
          'respondent_advocates'
        )
      "
    >
      <petitioner-respondents
        :matter="$store.state.matterStore.currentmatter"
      ></petitioner-respondents>
    </div>
    <!-- Listening History -->
    <div
      v-if="
        Object.keys($store.state.matterStore.currentmatter).includes(
          'listing_history'
        )
      "
    >
      <listing-details
        :matter="$store.state.matterStore.currentmatter"
      ></listing-details>
    </div>
    <!-- Crime Details & Lower Court -->
    <b-row>
      <!-- Crime Details -->
      <b-col
        class="col-12 col-md-6 mb-1"
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes(
            'crime_dic'
          )
        "
      >
        <crime-details
          :matter="$store.state.matterStore.currentmatter"
        ></crime-details>
      </b-col>
      <!-- Lower Court -->
      <b-col
        class="col-12 col-md-6 mb-1"
        v-if="
          Object.keys($store.state.matterStore.currentmatter).includes(
            'lower_court'
          )
        "
      >
        <lower-court
          :matter="$store.state.matterStore.currentmatter"
        ></lower-court>
      </b-col>
    </b-row>
    <!-- IA Details -->
    <div
      v-if="Object.keys($store.state.matterStore.currentmatter).includes('ia')"
    >
      <i-a-details
        :matter="$store.state.matterStore.currentmatter"
      ></i-a-details>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
// Section Imports
import BasicDetails from './components/BasicDetails.vue'
import CaseStatus from './components/CaseStatus.vue'
import ListingDetails from './components/ListingDetails.vue'
import PetitionerRespondents from './components/PetitionerRespondents.vue'
import IADetails from './components/IADetails.vue'
import CategoryDetails from './components/CategoryDetails.vue'
import LinkedMatters from './components/LinkedMatters.vue'
import LowerCourt from './components/LowerCourt.vue'
import CrimeDetails from './components/CrimeDetails.vue'

export default {
  components: {
    BRow,
    BCol,
    // Basic Details
    BasicDetails,
    CaseStatus,
    ListingDetails,
    PetitionerRespondents,
    IADetails,
    CategoryDetails,
    LinkedMatters,
    LowerCourt,
    CrimeDetails
  }
}
</script>
