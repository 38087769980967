<template>
  <section>
    <b-row class="justify-content-between" align-v="start">
      <b-col md="5" class="mb-1">
        <b-card>
          <b-form-group label="Select Court" label-for="court">
            <v-select
              v-model="currentCourt"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="courtOptions"
              :selectable="(option) => !option.value.includes('Supreme Court')"
              label="text"
              placeholder="Select Court"
              @input="onCourtSelect"
            />
          </b-form-group>
        </b-card>
        <!-- calendar -->
        <b-row v-if="currentCourt">
          <b-col class="col-12">
            <b-calendar block v-model="value" locale="en-US" @context="onContext" />
          </b-col>
        </b-row>
      </b-col>
      <b-col md="7">
        <b-overlay
          :show="$store.state.displayboardStore.displayBoardLoading"
          rounded="sm"
          variant="transparent"
          opacity="0.5"
          blur="2px"
        >
          <b-card
            v-if="$store.state.displayboardStore.displayBoardData.length"
            no-body
          >
            <b-table
              responsive="sm"
              :items="$store.state.displayboardStore.displayBoardData"
            />
          </b-card>
          <b-card
            v-else-if="
              !$store.state.displayboardStore.displayBoardData.length &&
              !$store.state.displayboardStore.displayBoardLoading
            "
            class="text-center"
          >
            <p>Record Not Found!</p>
          </b-card>
          <b-card v-else class="text-center">
            <p></p>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormCheckbox,
  BInputGroupAppend,
  BCard,
  BTable,
  BOverlay,
  BSpinner,
  BCalendar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormCheckbox,
    BInputGroupAppend,
    BCard,
    vSelect,
    BTable,
    BOverlay,
    BSpinner,
    BCalendar
  },
  directives: {
    Ripple
  },
  data() {
    return {
      currentCourt: null,
      courtOptions: [],
      isSelected: false,
      value: '',
      context: null
    }
  },
  beforeMount() {
    //   Court Setup
    this.$store.dispatch('courtStore/getAllHeadCourts').then((response) => {
      this.courtOptions = response
      console.log(this.courtOptions)
    })
  },

  methods: {
    onContext(ctx) {
      this.context = ctx
    },
    refreshEntireCaseCount() {
      this.$store.dispatch('displayboardStore/fetchDisplayBoard', {
        value: this.currentCourt.value
      })
    },
    onCourtSelect(value) {
      if (value) {
        this.$store.dispatch('displayboardStore/fetchDisplayBoard', {
          value: value.value
        })
      }
    }
  }
}
</script>
