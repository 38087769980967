<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Add New User</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-form-input v-show="false" autocomplete="off" />

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group label="Full Name" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="userData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Full Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required|alpha-num"
          >
            <b-form-group label="Username" label-for="username">
              <b-form-input
                id="username"
                placeholder="User Name"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                placeholder="username@example.com"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Mobile -->
          <validation-provider
            #default="validationContext"
            name="Mobile"
            rules="required|regex:^([0-9]+)$|min:10"
          >
            <b-form-group label="Mobile" label-for="mobile">
              <b-form-input
                id="mobile"
                placeholder="0987654321"
                v-model="userData.mobile"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- SC Adv. Id/AOR/Full Name -->
          <b-form-group
            label="Adv. Id/AOR/Full Name (Supreme Court)"
            label-for="scAOR"
          >
            <b-form-input
              id="scAOR"
              placeholder="Adv. SC AOR"
              v-model="userData.sc_advocate_key_id"
              trim
            />
          </b-form-group>

          <!-- User Court -->
          <b-form-group label="Select High Court" label-for="user-court">
            <v-select
              v-model="userData.court_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="courtOption"
              :clearable="true"
              placeholder="Select High Court"
              :reduce="(val) => val.value"
              input-id="user-court"
            />
          </b-form-group>

          <!-- Adv. Id/AOR/Full Name -->
          <b-form-group label="Adv. Id/AOR/Full Name" label-for="advId">
            <b-form-input
              id="advId"
              placeholder="Adv. High Court Id"
              v-model="userData.advocate_key_id"
              trim
            />
          </b-form-group>

          <!-- District Court Select -->
          <b-form-group
            label="Select District Court"
            label-for="user-district-court"
          >
            <v-select
              v-model="userData.district_court_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="districtOption"
              :clearable="true"
              :reduce="(val) => val.value"
              placeholder="Select District Court"
              input-id="user-district-court"
            />
          </b-form-group>

          <!-- Bar Code/Full Name (For District Court) -->
          <b-form-group
            label="Bar Code/Full Name (District Court)"
            label-for="district_advocate_key_id"
          >
            <b-form-input
              id="district_advocate_key_id"
              placeholder="Adv. District Id"
              v-model="userData.district_advocate_key_id"
              trim
            />
          </b-form-group>

          <!-- CAT Court -->
          <b-form-group label="Select CAT" label-for="user-cat-court">
            <v-select
              v-model="userData.tribunals.cat.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select CAT"
              input-id="user-cat-court"
            />
          </b-form-group>

          <!-- Advocate Name (For CAT Court) -->
          <b-form-group
            label="Advocate Name (CAT)"
            label-for="cat_advocate_key_id"
          >
            <b-form-input
              id="cat_advocate_key_id"
              placeholder="Adv. CAT Id"
              v-model="userData.tribunals.cat.aor"
              trim
            />
          </b-form-group>

          <!-- Confonet Court -->
          <b-form-group label="Select Confonet" label-for="user-confonet-court">
            <v-select
              v-model="userData.tribunals.confonet.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select Confonet"
              input-id="user-confonet-court"
            />
          </b-form-group>

          <!-- Advocate Name (For Confonet Court) -->
          <b-form-group
            label="Advocate Name (Confonet)"
            label-for="confonet_advocate_key_id"
          >
            <b-form-input
              id="confonet_advocate_key_id"
              placeholder="Adv. CAT Id"
              v-model="userData.tribunals.confonet.aor"
              trim
            />
          </b-form-group>

          <!-- DRT Court -->
          <b-form-group label="Select DRT" label-for="user-drt-court">
            <v-select
              v-model="userData.tribunals.drt.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select DRT"
              input-id="user-drt-court"
            />
          </b-form-group>

          <!-- Advocate Name (For DRT Court) -->
          <b-form-group
            label="Advocate Name (DRT)"
            label-for="drt_advocate_key_id"
          >
            <b-form-input
              id="drt_advocate_key_id"
              placeholder="Adv. DRT Id"
              v-model="userData.tribunals.drt.aor"
              trim
            />
          </b-form-group>

          <!-- RERA Court -->
          <b-form-group label="Select RERA" label-for="user-rera-court">
            <v-select
              v-model="userData.tribunals.rera.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select RERA"
              input-id="user-rera-court"
            />
          </b-form-group>

          <!-- Advocate Name (For RERA Court) -->
          <b-form-group
            label="Advocate Name (RERA)"
            label-for="rera_advocate_key_id"
          >
            <b-form-input
              id="rera_advocate_key_id"
              placeholder="Adv. RERA Id"
              v-model="userData.tribunals.rera.aor"
              trim
            />
          </b-form-group>

          <!-- Reat Court -->
          <b-form-group label="Select REAT" label-for="user-reat-court">
            <v-select
              v-model="userData.tribunals.reat.id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tribunalOption"
              :reduce="(val) => val.value"
              :clearable="true"
              placeholder="Select REAT"
              input-id="user-reat-court"
            />
          </b-form-group>

          <!-- Advocate Name (For REAT Court) -->
          <b-form-group
            label="Advocate Name (REAT)"
            label-for="reat_advocate_key_id"
          >
            <b-form-input
              id="reat_advocate_key_id"
              placeholder="Adv. REAT Id"
              v-model="userData.tribunals.reat.aor"
              trim
            />
          </b-form-group>

          <!-- Address -->
          <validation-provider
            #default="validationContext"
            name="Address"
            rules="required"
          >
            <b-form-group
              label="Address"
              label-for="address"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="address"
                placeholder="Office Address"
                v-model="userData.address"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Plan -->
          <validation-provider
            #default="validationContext"
            name="Subscription Plan"
            rules="required"
          >
            <b-form-group
              label="Subscription Plan"
              label-for="plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.plan_reference_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="$store.state.planStore.plansZeroList"
                :reduce="(val) => val.reference_id"
                :clearable="true"
                placeholder="Select Plan"
                label="name"
                input-id="plan"
              >
                <template slot="option" slot-scope="option">
                  <span></span>
                  {{ option.name }} - ({{ option.period_days }} days) (₹{{
                    option.price
                  }})
                </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.name }} - ({{ option.period_days }} days) (₹{{
                    option.price
                  }})
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <b-form-group label="Password" label-for="password">
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|min:8"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="password"
                  v-model="userData.password"
                  :type="passwordFieldTypeNew"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIconNew"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- confirm password -->
          <b-form-group label-for="password-confirm" label="Confirm Password">
            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required|confirmed:Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="password-confirm"
                  v-model="userData.new_password"
                  :type="passwordFieldTypeRetype"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false : null"
                  name="password-confirm"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIconRetype"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormInvalidFeedback,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active'
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true
    },
    courtOptions: {
      type: Array,
      required: true
    },
    districtOptions: {
      type: Array,
      required: true
    },
    tribunalOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      password,
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password'
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    }
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    }
  },
  setup(props, context) {
    var courtOption = props.courtOptions
    var districtOption = props.districtOptions
    var tribunalOption = props.tribunalOptions
    console.log('Context', context)
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      court_id: null,
      district_court_id: null,
      plan_reference_id: null,
      advocate_key_id: '',
      sc_advocate_key_id: '',
      district_advocate_key_id: '',
      tribunals: {
        cat: {
          id: '',
          aor: ''
        },
        confonet: {
          id: '',
          aor: ''
        },
        drt: {
          id: '',
          aor: ''
        },
        rera: {
          id: '',
          aor: ''
        },
        reat: {
          id: '',
          aor: ''
        }
      },
      address: '',
      test_user: true,
      mobile: '',
      password: '',
      new_password: ''
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      // console.log("User Data: ", userData.val);
      store
        .dispatch('userStore/addUser', { payload: userData.value })
        .then((response) => {
          if (response != null) {
            context.emit('refetch-data')
            context.emit('update:is-add-new-user-sidebar-active', false)
          }
        })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)

    return {
      userData,
      onSubmit,
      tribunalOption,
      districtOption,
      courtOption,
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
