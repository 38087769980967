<template>
  <!-- types -->
  <div :type="$store.state.causelistStore.causelists">
    <div v-for="causelist in $store.state.causelistStore.causelists">
      <b-card
        no-body
        class="m-0 p-0 rounded-0"
        :style="
          isDark
            ? 'background-color: #343d55; border-color: #343d55'
            : 'background-color: #f3f2f7; border-color: #e6e9eb'
        "
        :header="causelist.date"
        header-text-variant="black"
        header-raduis="0"
        header-class="m-0 p-1 rounded-0"
      >
        <template #header>
          <b-col class="col-12 text-start">
            <b-row class="justify-content-between">
              <b-col>
                <h4 class="text-black">{{ causelist.date }}<span class="font-weight-normal"> ({{ causelist.data.length }})</span></h4>
                <p class="font-weight-bold p-0 m-0">
                  <!-- {{ getTitleByCourtId(causelist.court_id) }} -->
                </p>
              </b-col>
              <div>
                <export-hearing-by-date
                  :date="causelist.date" :userId="id"
                ></export-hearing-by-date>
              </div>
            </b-row>
          </b-col>
        </template>

        <b-card no-body class="p-0 m-0 rounded-0">
          <b-table
            :items="causelist.data"
            :fields="fields"
            ref="refInvoiceListTable"
            responsive
            :primary-key="causelist._id"
            show-empty
            empty-text="No records found"
            class="position-relative"
          >
            <template #cell(title)="data">
              <h6
                class="mb-1 text-primary cursor-pointer"
                v-b-tooltip.hover
                title="View Matter"
                @click="
                  $router.push({
                    name: 'user-matter-preview',
                    params: { id: data.item._id }
                  })
                "
              >
                {{ data.item.title }}
              </h6>
            </template>
            <!-- Column: Unique Id -->
            <template #cell(unique_id)="data">
              <b-col>
                <div>
                  <h5 class="font-weight-bold" v-if="data.item.unique_id">
                    {{getUniqueId(data.item)}}
                  </h5>
                </div>
                <div v-if="Object.keys(data.item).includes('cause_name')">
                  <small v-if="data.item.cause_name">
                    [{{ data.item.cause_name }}]
                  </small>
                </div>
              </b-col>
            </template>
            <!-- Column: Unique Id -->
            <template #cell(court_id)="data">
              <b-col>
                <div v-if="Object.keys(data.item).includes('ctitle')">
                  <small class="text-secondary font-italic">{{
                    data.item.ctitle
                  }}</small>
                </div>
                <div
                  v-if="
                    Object.keys(data.item).includes('serial_number') ||
                    Object.keys(data.item).includes('court_number')
                  "
                >
                  <small
                    class="text-primary font-weight-bolder text-uppercase"
                    v-if="
                      data.item.court_number > 0 || data.item.serial_number > 0
                    "
                  >
                    Listed:
                  </small>
                  <small
                      v-if="Object.keys(data.item).includes('serial_number')"
                    >
                      <small
                        class="text-primary font-weight-bolder text-uppercase"
                        v-if="data.item.serial_number > 0"
                      >
                        [ Sr No. {{ data.item.serial_number }} ]
                      </small>
                    </small>
                    <small
                      v-if="Object.keys(data.item).includes('court_number')"
                    >
                      <small
                        class="text-primary font-weight-bolder text-uppercase"
                        v-if="
                          data.item.court_number > 0 || data.item.court_number === -10
                        "
                      >
                        - [ Court No.
                        {{
                          data.item.court_number === -10 ? 'C.J.' : data.item.court_number
                        }}
                        ]
                      </small>
                    </small>
                </div>
                <div v-if="Object.keys(data.item).includes('judges')">
                  <div v-if="data.item.judges">
                    <small
                      class="text-black font-weight-bold"
                      v-if="data.item.judges.length"
                      >{{ data.item.judges.length > 1 ? 'JUSTICES' : 'JUSTICE' }}
                      {{ data.item.judges.join(",") }}
                    </small>
                  </div>
                </div>
              </b-col>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-row class="text-nowrap">
                <b-col cols="auto" class="align-self-center col-8">
                  <div class="text-right">
                    <b-badge
                      variant="primary"
                      pill
                      v-b-tooltip.hover
                      title="View Matter"
                      class="badge-round cursor-pointer mr-1"
                      @click="
                        $router.push({
                          name: 'user-matter-preview',
                          params: { id: data.item._id }
                        })
                      "
                    >
                      <feather-icon
                        :id="`matter-${data.item._id}-preview-icon`"
                        icon="EyeIcon"
                        size="16"
                        class="mr-25"
                      />
                      <span>View</span>
                    </b-badge>
                  </div>
                  <div v-if="Object.keys(data.item).includes('last_order_url')">
                    <b-badge
                      variant="light"
                      pill
                      class="badge-round mt-2"
                      v-if="data.item.last_order_url"
                      :href="data.item.last_order_url.split('.pdf')[0] + '.pdf'"
                      target="_blank"
                    >
                      <feather-icon
                        :id="`order-${data.item._id}-preview-icon`"
                        icon="Link2Icon"
                        size="16"
                        class="mr-25 cursor-pointer text-primary"
                      />
                      <span class="text-primary">Last Order</span>
                    </b-badge>
                  </div>
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import ExportHearingByDate from './ExportHearingByDate.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

import {
  BCard,
  BCardHeader,
  BButton,
  BCollapse,
  BCardBody,
  BTable,
  BBadge,
  BCol,
  BRow,
  BDropdown,
  BDropdownItem,
  BCardText,
  VBTooltip,
  BOverlay
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BButton,
    BCollapse,
    BCardBody,
    BCardText,
    BTable,
    BBadge,
    BCol,
    BRow,
    BDropdown,
    BDropdownItem,
    BOverlay,
    ExportHearingByDate
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {

    return {
      id: this.userId,
      fields: [
        { key: 'unique_id', label: 'Case Ref. Number', sortable: false },
        { key: 'court_id', label: 'Court', sortable: false },
        { key: 'title', sortable: false },
        { key: 'actions' }
      ],
      collapseType: 'border'
    }
  },
  beforeMount() {

  },
  methods: {

  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  }
}
</script>
