<template>
  <!-- Table Container Card -->
  <div>
    <export-enquiries/>
    <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12" md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h5>Enquiries</h5>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="8">
          <b-row class="d-flex align-items-center justify-content-end">
            <b-col cols="4">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                type="search"
                debounce="500"
              />
            </b-col>
            <b-col cols="4">
              <v-select
                v-model="stateFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="stateOptions"
                :reduce="(val) => val.value"
                class="invoice-filter-select"
                placeholder="Select State"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-col>
            <b-col cols="4">
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-overlay
      :show="$store.state.ticketStore.ticketLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <b-table
        ref="refInvoiceListTable"
        :items="fetchTickets"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <template #cell(fullname)="data">
          <div>
            {{ data.item.fullname }}
          </div>
        </template>

        <template #cell(state)="data">
          <div>{{ data.item.district }}, {{ data.item.state }}</div>
        </template>

        <!-- Column: Balance -->
        <template #cell(datetime)="data">
          <div>
            {{ getDate(data.item.datetime) }}
          </div>
        </template>

        <!-- Column: Balance -->
        <template #cell(status)="data">
          <template v-if="data.item.status === 'PENDING'">
            <b-badge pill variant="primary"> {{ data.item.status }} </b-badge>
          </template>
          <template v-else-if="data.item.status === 'RESOLVED'">
            <b-badge pill variant="info"> {{ data.item.status }} </b-badge>
          </template>
          <template v-else-if="data.item.status === 'FOLLOWUP'">
            <b-badge pill variant="warning"> {{ data.item.status }} </b-badge>
          </template>
          <template v-else>
            <b-badge pill variant="info"> {{ data.item.status }} </b-badge>
          </template>
        </template>

        <!-- Column: Balance -->
        <template #cell(action)="data">
          <update-inquiry
            @refetch-data="refetchData"
            :data="data.item"
          ></update-inquiry>
        </template>
      </b-table>
    </b-overlay>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BModal,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BPagination,
  BTooltip,
  BOverlay,
  VBTooltip,
  VBModal
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import UpdateInquiry from './update-inquiry.vue'

// import store from '@/store'
import useSupportInquiry from './useNewInquiry'
import ExportEnquiries from './export-enquiries.vue'

export default {
  components: {
    BCard,
    BRow,
    BModal,
    BCardText,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BListGroup,
    BListGroupItem,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BOverlay,
    vSelect,
    UpdateInquiry,
    ExportEnquiries
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple
  },
  methods: {
    getDate(date) {
      return moment(date).format('lll')
    }
  },

  setup(props) {
    let userData = JSON.parse(localStorage.getItem('userData'))
    const statusOptions = [
      { label: 'Pending', value: 'PENDING' },
      { label: 'Resolved', value: 'RESOLVED' },
      { label: 'Follow Up', value: 'FOLLOWUP' }
    ]

    const stateOptions = [
      {
        value: 'Andra Pradesh',
        label: 'Andra Pradesh'
      },
      {
        value: 'Arunachal Pradesh',
        label: 'Arunachal Pradesh'
      },
      {
        value: 'Assam',
        label: 'Assam'
      },
      {
        value: 'Bihar',
        label: 'Bihar'
      },
      {
        value: 'Chhattisgarh',
        label: 'Chhattisgarh'
      },
      {
        value: 'Goa',
        label: 'Goa'
      },
      {
        value: 'Gujarat',
        label: 'Gujarat'
      },
      {
        value: 'Haryana',
        label: 'Haryana'
      },
      {
        value: 'Himachal Pradesh',
        label: 'Himachal Pradesh'
      },
      {
        value: 'Jammu and Kashmir',
        label: 'Jammu and Kashmir'
      },
      {
        value: 'Jharkhand',
        label: 'Jharkhand'
      },
      {
        value: 'Karnataka',
        label: 'Karnataka'
      },
      {
        value: 'Kerala',
        label: 'Kerala'
      },
      {
        value: 'Madya Pradesh',
        label: 'Madya Pradesh'
      },
      {
        value: 'Maharashtra',
        label: 'Maharashtra'
      },
      {
        value: 'Manipur',
        label: 'Manipur'
      },
      {
        value: 'Meghalaya',
        label: 'Meghalaya'
      },
      {
        value: 'Mizoram',
        label: 'Mizoram'
      },
      {
        value: 'Nagaland',
        label: 'Nagaland'
      },
      {
        value: 'Orissa',
        label: 'Orissa'
      },
      {
        value: 'Punjab',
        label: 'Punjab'
      },
      {
        value: 'Rajasthan',
        label: 'Rajasthan'
      },
      {
        value: 'Sikkim',
        label: 'Sikkim'
      },
      {
        value: 'Tamil Nadu',
        label: 'Tamil Nadu'
      },
      {
        value: 'Telangana',
        label: 'Telangana'
      },
      {
        value: 'Tripura',
        label: 'Tripura'
      },
      {
        value: 'Uttaranchal',
        label: 'Uttaranchal'
      },
      {
        value: 'Uttar Pradesh',
        label: 'Uttar Pradesh'
      },
      {
        value: 'West Bengal',
        label: 'West Bengal'
      },
      {
        value: 'Andaman and Nicobar Islands',
        label: 'Andaman and Nicobar Islands'
      },
      {
        value: 'Chandigarh',
        label: 'Chandigarh'
      },
      {
        value: 'Dadar and Nagar Haveli',
        label: 'Dadar and Nagar Haveli'
      },
      {
        value: 'Daman and Diu',
        label: 'Daman and Diu'
      },
      {
        value: 'Delhi',
        label: 'Delhi'
      },
      {
        value: 'Lakshadeep',
        label: 'Lakshadeep'
      },
      {
        value: 'Pondicherry',
        label: 'Pondicherry'
      }
    ]

    const {
      fetchTickets,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      stateFilter,

      refetchData
    } = useSupportInquiry({ type: props.type })

    return {
      userData,
      fetchTickets,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      statusOptions,
      stateOptions,
      stateFilter,
      refetchData,
      formatDate,

      avatarText
    }
  }
}
</script>
