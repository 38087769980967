<template>
  <b-card
    border-variant="info"
    class="m-0 mb-md-0 mt-2"
    no-body
    header-bg-variant="info"
  >
    <template #header>
      <b-col>
        <b-row class="justify-content-between">
          <b-col class="p-0 m-0">
            <h5 class="font-weight-bolder m-0 text-dark">Purchase History</h5>
          </b-col>
        </b-row>
      </b-col>
    </template>
    <div v-if="$store.state.planStore.transactionList.length">
      <b-table small :fields="fields" :items="$store.state.planStore.transactionList" responsive="sm">
      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(plan)="data">
        {{ data.item.plan.name }} - {{ data.item.plan.is_free ? '(Free)' : '(Paid)' }}
      </template>

      <!-- A virtual composite column -->
      <template #cell(created_at)="data">
        {{ getSubDate(data.item.plan.created_at) }}
      </template>

      <template #cell(type)="data">
        <b-badge pill class="my-75">
          {{ data.value }}
        </b-badge>
      </template>

      <!-- A virtual composite column -->
      <template #cell(price)="data">
        {{ '₹' + data.item.plan.price }}
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BProgress,
  BBadge,
  BRow,
  BCol,
  BButton
} from 'bootstrap-vue'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BProgress,
    BBadge,
    BRow,
    BCol,
    BButton
  },
  props: {
    uData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      user: this.uData,
      fields: [
        // A virtual column that doesn't exist in items
        // A column that needs custom formatting
        { key: 'plan', label: 'Plan Name', sortable: false },
        { key: 'created_at', label: 'Created At' },
        // A regular column
        { key: 'type', label: 'Status', sortable: false },
        // A virtual column made up from two fields
        { key: 'price', label: 'Amount' }
      ]
    }
  },
  methods:{
    getSubDate(date) {
      return moment(date).format('LLL')
    },
  },
  setup(props){
    console.log(props.uData._id, "dsf")
    store.dispatch('planStore/fetchTransactions', { id: props.uData._id })
    return {

    }
  }
}
</script>

<style></style>
