export default [
  {
    header: 'Administration'
  },
  {
    title: 'Analytics',
    route: 'analytics',
    icon: 'TrelloIcon'
  },
  {
    title: 'Courts',
    route: 'court-list',
    icon: 'CalendarIcon'
  },
  {
    title: 'Plans',
    route: 'plans-list',
    icon: 'AwardIcon'
  },
  {
    title: 'Users',
    route: 'users-list',
    icon: 'UsersIcon'
  },
  {
    title: 'Support Tickets',
    route: 'support-ticket',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'New Inquiry',
    route: 'new-inquiry',
    icon: 'MessageCircleIcon'
  },
  {
    title: 'Entire Cases',
    route: 'entire-cases',
    icon: 'LayoutIcon',
  }
]
