<template>
  <div v-if="userData !== null">
    <div v-if="Object.keys(userData).includes('plan')">
      <user-current-plan
        v-if="userData.plan !== null"
        :uData="userData"
        @refetch-data="refetchData"
      ></user-current-plan>
      <!-- <add-new-plan
        v-else
        :uData="userData"
        @refetch-data="refetchData"
      ></add-new-plan> -->
    </div>
    <!-- <add-new-plan
      v-else
      :uData="userData"
      @refetch-data="refetchData"
    ></add-new-plan> -->
    <user-purchase-history :uData="userData"></user-purchase-history>
  </div>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar } from 'bootstrap-vue'
import UserCurrentPlan from './components/UserCurrentPlan.vue'
import UserPurchaseHistory from './components/UserPurchaseHistory.vue'
import { ref } from '@vue/composition-api'
import AddNewPlan from './components/AddNewPlan.vue'
import store from '@/store'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    UserCurrentPlan,
    UserPurchaseHistory,
    AddNewPlan
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const userData = ref(null)
    store
      .dispatch('userStore/fetchUserById', { id: props.userId })
      .then((response) => {
        userData.value = response
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching user',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })

    function refetchData() {
      store
        .dispatch('userStore/fetchUserById', { id: props.userId })
        .then((response) => {
          userData.value = null
          setTimeout(() => (userData.value = response), 100)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching user',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        })
    }

    return {
      userData,
      refetchData
    }
  }
}
</script>

<style></style>
