<template>
  <section>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-toggle="'matter-order-judgment-' + buttonType"
      :variant="buttonType === 'sm' ? 'outline-success' : 'success'"
      :class="buttonType === 'sm' ? 'btn-sm' : 'mb-75'"
      block
      @click="fetchJudgment"
    >
      Orders/Judgments
    </b-button>
    <template>
      <b-sidebar
        :id="'matter-order-judgment-' + buttonType"
        sidebar-class="sidebar-lg"
        bg-variant="white"
        shadow
        backdrop
        right
      >
        <template #header="{ hide }">
          <b-col>
            <b-row class="justify-content-between px-1 py-1">
              <h5 class="mb-0">Orders & Judgments</h5>
              <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
              />
            </b-row>
          </b-col>
        </template>
        <template #default="{ hide }">
          <b-row align-v="stretch">
            <b-col d-flex justify-center align-center>
              <!-- Body -->
              <b-overlay
                :show="$store.state.matterStore.judgmentLoading"
                rounded="sm"
                variant="transparent"
                opacity="0.5"
                blur="2px"
              >
                <!-- From -->

                <div v-if="$store.state.matterStore.judgmentList.length">
                  <!-- badge -->
                  <b-list-group>
                    <div
                      v-for="(item, index) in $store.state.matterStore
                        .judgmentList"
                      v-bind:key="item._id"
                    >
                      <b-list-group-item
                        :href="item.url.replace('http://s3.mymunshi.live', 'https://app.mymunshi.in/exports').split('.pdf')[0] + '.pdf'"
                        target="_blank"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <b-row class="align-items-center">
                          <b-avatar
                            variant="secondary"
                            class="ml-1"
                            size="2rem"
                          >
                            <feather-icon icon="FileTextIcon" />
                          </b-avatar>
                          <span class="ml-1">{{
                            formatDate(item.datetime, {
                              month: 'short',
                              day: 'numeric',
                              year: 'numeric'
                            })
                          }}</span>
                        </b-row>
                        <b-badge variant="primary" pill class="badge-round">
                          <feather-icon
                            :id="`invoice-row-${item._id}-preview-icon`"
                            icon="Link2Icon"
                            size="16"
                            class="mr-25 cursor-pointer"
                          />
                          <span>Preview</span>
                        </b-badge>
                      </b-list-group-item>
                    </div>
                  </b-list-group>
                </div>
                <h6
                  v-if="
                    !$store.state.matterStore.judgmentList.length &&
                    !$store.state.matterStore.judgmentLoading
                  "
                  class="text-center mt-2"
                >
                  Judgment Not Available!
                </h6>
              </b-overlay>
            </b-col>
          </b-row>
        </template>
        <template #footer>
          <div class="px-1 py-1 text-center">
            <ExportAllJudgmentButton
              :matter_id="matter._id"
              :userId="user_id"
            ></ExportAllJudgmentButton>
          </div>
        </template>
      </b-sidebar>
    </template>
  </section>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BBadge,
  BRow,
  BCol,
  BListGroup,
  BAvatar,
  BListGroupItem,
  VBToggle,
  BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { formatDate } from '@core/utils/filter'
import ExportAllJudgmentButton from './ExportAllJudgmentButton.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BBadge,
    BRow,
    BCol,
    BAvatar,
    BOverlay,
    BListGroup,
    BListGroupItem,
    ExportAllJudgmentButton
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  props: {
    data: {
      default: null
    },
    type: {
      default: 'lg'
    },
    userId: {
      default: null
    }
  },
  data() {
    return {
      matter: this.data,
      buttonType: this.type,
      user_id: this.userId
    }
  },
  methods: {
    fetchJudgment() {
      this.$store.dispatch('matterStore/fetchMatterJudgment', {matterId: this.matter._id})
    }
  },
  setup() {
    return {
      formatDate
    }
  }
}
</script>
