<template>
  <app-timeline v-if="userData != null">
    <!-- Cases From Court -->
    <li
      v-bind="$attrs"
      class="timeline-item"
      :class="[`timeline-variant-success`, `timeline-item-fill-border-success`]"
      v-on="$listeners"
    >
      <div
        class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
      >
        <feather-icon icon="ServerIcon" />
      </div>

      <slot>
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6 v-text="'Cases Backfill Completed'" />
          <b-form-checkbox
            :checked="caseBackfilled"
            class="custom-control-success"
            name="check-button"
            switch
            @change="changeStatus({ checked: $event, type: 'cases' })"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </slot>
    </li>
    <!-- Cases Import -->
    <li
      v-bind="$attrs"
      class="timeline-item"
      :class="[`timeline-variant-success`, `timeline-item-fill-border-success`]"
      v-on="$listeners"
    >
      <div
        class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
      >
        <feather-icon icon="DownloadIcon" />
      </div>

      <slot>
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6 v-text="'Imports Backfill Completed'" />
          <b-form-checkbox
            :checked="importsBackfilled"
            class="custom-control-success"
            name="check-button"
            switch
            @change="changeStatus({ checked: $event, type: 'imports' })"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </slot>
    </li>
    <!-- Orders -->
    <li
      v-bind="$attrs"
      class="timeline-item"
      :class="[`timeline-variant-success`, `timeline-item-fill-border-success`]"
      v-on="$listeners"
    >
      <div
        class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
      >
        <feather-icon icon="FileTextIcon" />
      </div>

      <slot>
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6 v-text="'Orders Backfill Completed'" />
          <b-form-checkbox
            :checked="orderBackfilled"
            class="custom-control-success"
            name="check-button"
            switch
            @change="changeStatus({ checked: $event, type: 'orders' })"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </slot>
    </li>
    <!-- debug Status -->
    <li
      v-bind="$attrs"
      class="timeline-item"
      :class="[`timeline-variant-success`, `timeline-item-fill-border-success`]"
      v-on="$listeners"
    >
      <div
        class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle"
      >
        <feather-icon icon="DownloadIcon" />
      </div>

      <slot>
        <div
          class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
        >
          <h6 v-text="'Debug Mode'" />
          <b-form-checkbox
            :checked="isDevelpmentDone"
            class="custom-control-success"
            name="check-button"
            switch
            @change="changeStatus({ checked: $event, type: 'debug' })"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </div>
      </slot>
    </li>
    <b-row class="justify-content-end">
      <b-button
        class="mt-2 mx-2"
        variant="primary"
        @click="changeBackFillStatus()"
      >
        Update
      </b-button>
    </b-row>
  </app-timeline>
</template>

<script>
import { BFormCheckbox, BButton, BRow } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  components: {
    BFormCheckbox,
    AppTimeline,
    BButton,
    BRow
  },
  props: {
    uData: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const userData = ref(props.uData)
    const caseBackfilled = ref(false)
    const importsBackfilled = ref(false)
    const isDevelpmentDone = ref(false)
    const orderBackfilled = ref(false)

    function changeStatus({ checked, type }) {
      if (type === 'cases') {
        caseBackfilled.value = checked
      }
      if (type === 'imports') {
        importsBackfilled.value = checked
      }
      if (type === 'orders') {
        orderBackfilled.value = checked
      }
      if (type === 'debug') {
        isDevelpmentDone.value = checked
      }
      console.log(
        caseBackfilled.value,
        importsBackfilled.value,
        orderBackfilled.value
      )
    }

    function initializeData() {
      console.log('Initializing')
      if (Object.keys(userData.value).includes('backfill')) {
        if (Object.keys(userData.value.backfill).includes('cases')) {
          if (userData.value.backfill.cases !== null) {
            caseBackfilled.value = userData.value.backfill.cases
          } else {
            caseBackfilled.value = false
          }
        } else {
          caseBackfilled.value = false
        }
        if (Object.keys(userData.value.backfill).includes('imports')) {
          if (userData.value.backfill.imports !== null) {
            importsBackfilled.value = userData.value.backfill.imports
          } else {
            importsBackfilled.value = false
          }
        } else {
          importsBackfilled.value = false
        }
        if (Object.keys(userData.value.backfill).includes('orders')) {
          if (userData.value.backfill.orders !== null) {
            orderBackfilled.value = userData.value.backfill.orders
          } else {
            orderBackfilled.value = false
          }
        } else {
          orderBackfilled.value = false
        }
        if (Object.keys(userData.value.backfill).includes('debug')) {
          if (userData.value.backfill.debug !== null) {
            isDevelpmentDone.value = userData.value.backfill.debug
          } else {
            isDevelpmentDone.value = false
          }
        } else {
          isDevelpmentDone.value = false
        }
      } else {
        caseBackfilled.value = false
        importsBackfilled.value = false
        orderBackfilled.value = false
        isDevelpmentDone.value = false
      }
      console.log(
        'Status',
        caseBackfilled.value,
        importsBackfilled.value,
        orderBackfilled.value,
        isDevelpmentDone.value
      )
    }

    initializeData()

    function refetchData() {
      store
        .dispatch('userStore/fetchUserById', { id: userData.value._id })
        .then((response) => {
          userData.value = response
          initializeData()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching user',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        })
    }

    function changeBackFillStatus() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You really want to update the status',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        console.log(result)
        if (result.value) {
          const payload = {
            user_id: userData.value._id,
            backfill: {
              cases: caseBackfilled.value,
              imports: importsBackfilled.value,
              orders: orderBackfilled.value,
              debug: isDevelpmentDone.value
            }
          }
          console.log('Payload', payload)
          store
            .dispatch('userStore/updateUser', {
              payload
            })
            .then((response) => {
              console.log('US', response)
              if (response !== null) {
                this.$swal({
                  icon: 'success',
                  title: 'Updated',
                  text: 'Status has been updated.',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
                refetchData()
              }
            })
        }
        if (result.isDismissed) {
          initializeData()
        }
      })
    }

    return {
      userData,
      caseBackfilled,
      importsBackfilled,
      orderBackfilled,
      refetchData,
      changeBackFillStatus,
      changeStatus,
      isDevelpmentDone
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes

// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

$timeline-border-color: $border-color;

/* Generate:
  *  Apply background color to dot
  */
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== 'base' {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;
          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }
        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;
  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient(
          $theme-dark-border-color,
          $theme-dark-card-bg
        );
      }
    }
    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
