<template>
  <section v-if="data.case_details !== null">
    <h3 class="pb-1">Case Status</h3>
    <b-card no-body class="py-75">
      <!-- First Hearing Date -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">First Hearing</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('first_hearing_date')"
          >
            {{ data.case_details.first_hearing_date }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Next Hearing Date -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Last Hearing</h6>
        </b-col>
        <b-col class="col-7">
          <div v-if="Object.keys(data).includes('listing_history')">
            <div v-if="data.listing_history.length">
              <p
                class="m-0 text-primary"
                v-if="
                  Object.keys(data.listing_history[0]).includes('hearing_at')
                "
              >
                {{ data.listing_history[0].hearing_at }}
              </p>
              <p class="m-0" v-else>--</p>
            </div>
            <p class="m-0" v-else>--</p>
          </div>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Next Hearing Date -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Next Hearing</h6>
        </b-col>
        <b-col class="col-7">
          <div v-if="Object.keys(data).includes('hearing_dates')">
            <p
              class="m-0 text-success font-weight-bold"
              v-if="data.hearing_dates !== null"
            >
              {{ getNextDate({ dates: data.hearing_dates }) }}
            </p>
            <p class="m-0" v-else>--</p>
          </div>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Decision Date -->
      <b-row
        class="px-1"
        v-if="Object.keys(data.case_details).includes('decision_date')"
      >
        <b-col class="col-5">
          <h6 class="m-0">Decision Date</h6>
        </b-col>
        <b-col class="col-7">
          <p class="m-0">
            {{ data.case_details.decision_date }}
          </p>
        </b-col>
      </b-row>
      <hr v-if="Object.keys(data.case_details).includes('decision_date')" style="margin: 5px 0" />
      <!-- Status -->
      <b-row
        class="px-1"
        v-if="Object.keys(data.case_details).includes('case_status')"
      >
        <b-col class="col-5">
          <h6 class="m-0">Case Status</h6>
        </b-col>
        <b-col class="col-7">
          <p class="m-0">
            {{ data.case_details.case_status }}
          </p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Nature of Dis. -->
      <b-row
        class="px-1"
        v-if="Object.keys(data.case_details).includes('nature_of_disposal')"
      >
        <b-col class="col-5">
          <h6 class="m-0">Nature of Dis.</h6>
        </b-col>
        <b-col class="col-7">
          <p class="m-0">
            {{ data.case_details.nature_of_disposal }}
          </p>
        </b-col>
      </b-row>
      <hr v-if="Object.keys(data.case_details).includes('nature_of_disposal')" style="margin: 5px 0" />
      <!-- Court Number -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Court No. & Judge</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('court_number')"
          >
            {{ data.case_details.court_number }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  },
  methods: {
    getNextDate({ dates }) {
      var date = '--'
      var dd = []
      const filteredDates = dates.filter(
        (date) => moment(date) >= moment().startOf('day')
      )
      for (let i = 0; i < filteredDates.length; i++) {
        dd.push(moment(filteredDates[i]).format('Do, MMMM YYYY'))
      }
      date = dd.join(' || ')
      return date
    }
  },
  setup() {
    return {
      formatDate
    }
  }
}
</script>
