export default [
  {
    header: 'Settings',
  },
  {
    title: 'Account Settings',
    route: 'account-setting',
    icon: 'UserIcon',
  },
  {
    header: 'Others',
  },
  {
    title: 'Push Notification',
    route: 'push-notification',
    icon: 'BellIcon',
  }
]
  