<template>
  <section class="pb-1">
    <h5 class="text-nowrap font-weight-bolder" v-if="data.unique_id">
      {{ getUniqueId(data) }}
      <b-badge
        variant="light-primary"
        class="mx-1"
        v-if="data.case_status.toLowerCase() === 'pending'"
      >
        <span>{{ data.case_status }}</span>
      </b-badge>
      <b-badge variant="light-danger" class="mx-1" v-else>
        <span>{{ data.case_status }}</span>
      </b-badge>
    </h5>
    <div>
      <p class="text-secondary font-italic">
        {{ data.ctitle }}
      </p>
    </div>
    <h4 class="text-primary">{{ data.title }}</h4>

    <p class="text-secondary">File No.: {{ data.file_no }}</p>
  </section>
</template>

<script>
import { BBadge, BRow } from 'bootstrap-vue'

export default {
  components: { BBadge, BRow },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  },
  beforeMount() {},
  methods: {}
}
</script>
