<template>
  <div v-if="userData != null">
    <!-- <b-card title="User Settings" class="m-0"> </b-card> -->
    <b-card title="Back Filling" class="m-0 mt-1">
      <user-back-filling :uData="userData"> </user-back-filling>
    </b-card>
  </div>
  <b-card v-else></b-card>
</template>

<script>
import { BCard, BImg, BMedia, BAvatar } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import UserBackFilling from './components/UserBackFilling.vue'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    UserBackFilling
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      id: this.userId
    }
  },
  setup(props) {
    const userData = ref(null)
    store
      .dispatch('userStore/fetchUserById', { id: props.userId })
      .then((response) => {
        userData.value = response
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching user',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })

    function refetchData() {
      store
        .dispatch('userStore/fetchUserById', { id: props.userId })
        .then((response) => {
          userData.value = null
          setTimeout(() => (userData.value = response), 100)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching user',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        })
    }

    function changeUserStatus(enabled) {
      let str = enabled ? 'enable' : 'disable'
      this.$swal({
        title: 'Are you sure?',
        text: 'You really want to ' + str + ' this user',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, ' + str + ' it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          store
            .dispatch('userStore/updateUser', {
              payload: { user_id: this.userId, enabled: enabled }
            })
            .then((response) => {
              console.log('US', response)
              if (response !== null) {
                this.$swal({
                  icon: 'success',
                  title: enabled ? 'Enabled!' : 'Disabled!',
                  text: enabled
                    ? 'User has been enabled!'
                    : 'User has been disabled.',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
                this.refetchData()
              }
            })
        }
      })
    }

    return {
      userData,
      refetchData,
      changeUserStatus
    }
  }
}
</script>

<style></style>
