var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('basic-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'case_details'
      )
    )?_c('div',[_c('case-status',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter,"detail":_vm.$store.state.matterStore.currentmatter.case_details}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes('category')
    )?_c('div',[_c('category-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'linked_matters'
      )
    )?_c('div',[(_vm.$store.state.matterStore.currentmatter.linked_matters)?_c('linked-matters',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}):_vm._e()],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'petitioner_advocates'
      ) ||
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'respondent_advocates'
      )
    )?_c('div',[_c('petitioner-respondents',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'listing_history'
      )
    )?_c('div',[_c('listing-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),_c('b-row',[(
        Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
          'crime_dic'
        )
      )?_c('b-col',{staticClass:"col-12 col-md-6 mb-1"},[_c('crime-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
        Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
          'lower_court'
        )
      )?_c('b-col',{staticClass:"col-12 col-md-6 mb-1"},[_c('lower-court',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e()],1),(Object.keys(_vm.$store.state.matterStore.currentmatter).includes('ia'))?_c('div',[_c('i-a-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }