import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
import store from '@/store/index.js'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSupportTicket() {
  // Use toast
  // const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: false },
    { key: 'message', sortable: false },
    { key: 'datetime', label: 'Created At', sortable: false },
    { key: 'status', sortable: false },
    { key: 'action', label: "Action", sortable: false },
  ]
  const perPage = ref(50)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [50, 100, 200, 500]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchTickets = (ctx, callback) => {


    let queryParams = {
      perPage: perPage.value,
      page: currentPage.value,
    }

    if(statusFilter.value !== null){
      queryParams['filters[status]'] = statusFilter.value
    }

    if (searchQuery.value) {
      queryParams['query'] = searchQuery.value
    }

    store
      .dispatch('ticketStore/fetchTickets', {ctx: this, queryParams: queryParams})
      .then((response) => {
        console.log({ Tickets: response })
        callback(response.data)
        totalInvoices.value = response.meta.total
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return {
    fetchTickets,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    refetchData
  }
}
