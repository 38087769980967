<template>
  <b-row>
    <b-col md="8" lg="6">
      <b-overlay
        :show="$store.state.settingStore.pushLoading"
        rounded="sm"
        variant="transparent"
        opacity="0.5"
        blur="2px"
      >
        <b-card
          header="Send General Push"
          header-tag="header"
          footer-tag="footer"
        >
          <div class="mb-1">
            <label for="textarea-default">Heading *</label>
            <b-form-input
              v-model="heading"
              name="heading"
              placeholder="Heading"
            />
          </div>

          <div>
            <label for="textarea-default">Message *</label>
            <b-form-textarea
              v-model="message"
              id="textarea-default"
              placeholder="Enter Message"
              rows="3"
            />
          </div>
          <small>Regards,</small>
          <br />
          <small>MyMynshi Team</small>
          <b-form-radio-group
            v-model="selected"
            :options="options"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            class="demo-inline-spacing"
          />
          <b-form-group
            v-if="selected !== 'all'"
            label="Mobile Number (Single) *"
            label-for="userMobile"
            class="mt-1"
          >
            <b-form-input
              id="userMobile"
              placeholder="Enter Mobile Number"
              v-model="mobile"
            />
          </b-form-group>
          <template #footer>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click.prevent="submitForm"
            >
              Send Push Notification
            </b-button>
          </template>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BOverlay,
  BCard,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BFormRadioGroup,
  BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormTextarea,
    BFormRadioGroup,
    BButton
  },
  directives: {
    Ripple
  },
  data() {
    return {
      mobile: '',
      heading: '',
      message: '',
      selected: 'all',
      options: [
        { item: 'all', name: 'All' },
        { item: 'single', name: 'Single User' }
      ]
    }
  },
  methods: {
    submitForm() {
      event.preventDefault()
      if (this.selected === 'single') {
        if (
          /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(
            this.mobile
          ) &&
          this.message !== '' && this.heading !== ''
        ) {
          const payload = {
            test: this.mobile,
            message: this.message,
            heading: this.heading
          }
          this.$swal({
            title: 'Warning',
            icon: 'warning',
            text: 'You really want to send push notification.',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Yes, Send',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then((result) => {
            if (result.value) {
              this.$store.dispatch('settingStore/sendGeneralPush', {
                ctx: this,
                payload: payload
              })
            }
          })
        } else {
          console.log('Invalid')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Enter Valid Fields!',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        }
      } else {
        if (this.message !== '' && this.heading !== '') {
          const payload = {
            message: this.message,
            heading: this.heading
          }
          this.$swal({
            title: 'Warning',
            icon: 'warning',
            text: 'You really want to send push notification to all users.',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Yes, Send',
            customClass: {
              confirmButton: 'btn btn-danger',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then((result) => {
            if (result.value) {
              this.$store.dispatch('settingStore/sendGeneralPush', {
                ctx: this,
                payload: payload
              })
            }
          })
        } else {
          console.log('Invalid')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Enter Valid Fields!',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        }
      }
    }
  }
}
</script>
