<template>
  <div v-if="userData">
    <user-update
      v-if="userData !== null"
      :is-update-user-sidebar-active.sync="isUpdateUserSidebarActive"
      :court-options="courtOptions"
      :district-options="districtOptions"
      :tribunal-options="tribunalOptions"
      :data="userData"
      @refetch-data="refetchData"
    />
    <user-court-update
      v-if="userData !== null"
      :is-update-court-sidebar-active.sync="isUpdateCourtSidebarActive"
      :court-options="courtOptions"
      :district-options="districtOptions"
      :tribunal-options="tribunalOptions"
      :data="userData"
      @refetch-data="refetchData"
    />
    <b-card>
      <b-row>
        <!-- User Info: Left col -->
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- User Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="userData.avatar"
              :text="avatarText(userData.fullName)"
              :variant="`light-${resolveUserRoleVariant(userData.role)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <small>@{{ userData.username }}</small>
                <h4 class="mb-0">
                  {{ userData.fullName }}
                </h4>
                <span class="card-text">{{ userData.email }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  variant="primary"
                  @click="isUpdateUserSidebarActive = true"
                >
                  Profile
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="ml-1"
                  v-if="userData.enabled"
                  @click="changeUserStatus(false)"
                >
                  Disable
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="ml-1"
                  v-if="!userData.enabled"
                  @click="changeUserStatus(true)"
                >
                  Enable
                </b-button>
              </div>
              <div class="d-flex flex-wrap mt-1">
                <b-button
                  variant="light"
                  class=""
                  @click="isUpdateCourtSidebarActive = true"
                >
                  Courts
                </b-button>
                <user-tribunals-update
                  v-if="userData !== null"
                  :tribunal-options="tribunalOptions"
                  :data="userData"
                />
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" md="6">
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon icon="UserCheckIcon" class="mr-75" />
              </th>
              <td class="pb-50">
                {{ userData.advocate_key_id }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="HomeIcon" class="mr-75" />
              </th>
              <td v-if="Object.keys(userData).includes('court')">
                {{
                  userData.court !== null
                    ? Object.keys(userData.court).includes('label')
                      ? userData.court.label
                      : '--'
                    : '--'
                }}
              </td>
              <td v-else>--</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.enabled ? 'Active' : 'In-Active' }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="PhoneIcon" class="mr-75" />
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.mobile }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="FlagIcon" class="mr-75" />
              </th>
              <td>
                {{ userData.address }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="FileTextIcon" class="mr-75" />
              </th>
              <td>
                {{ getDate(userData.time) }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <!-- <b-row v-if="userData">
      <b-col cols="12" xl="9" lg="8" md="7">
        
      </b-col> -->
    <!-- <b-col cols="12" md="5" xl="3" lg="4" align-self="stretch">
        <user-view-user-plan-card :userId="userId" />
      </b-col> -->
    <!-- </b-row> -->
  </div>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useUsersList from '../../users-list/useUsersList'
import store from '@/store'
import { ref } from '@vue/composition-api'
import defaultConfig from '@/configs/defaultConfig'
import UserUpdate from '../UserUpdate.vue'
import moment from 'moment'
import UserCourtUpdate from './UserCourtUpdate.vue'
import UserTribunalsUpdate from './UserTribunalsUpdate.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    UserUpdate,
    UserCourtUpdate,
    UserTribunalsUpdate,
    UserViewUserPlanCard
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  methods: {
    getDate(time) {
      let expiredDate = moment(time)
      return expiredDate.format('MMM Do YYYY')
    }
  },
  setup(props) {
    const isUpdateUserSidebarActive = ref(false)
    const isUpdateCourtSidebarActive = ref(false)
    const userData = ref(null)
    store
      .dispatch('userStore/fetchUserById', { id: props.userId })
      .then((response) => {
        userData.value = response
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching user',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })

    function refetchData() {
      store
        .dispatch('userStore/fetchUserById', { id: props.userId })
        .then((response) => {
          userData.value = null
          setTimeout(() => (userData.value = response), 100)
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching user',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        })
    }

    function changeUserStatus(enabled) {
      let str = enabled ? 'enable' : 'disable'
      this.$swal({
        title: 'Are you sure?',
        text: 'You really want to ' + str + ' this user',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, ' + str + ' it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          store
            .dispatch('userStore/updateUser', {
              payload: { user_id: this.userId, enabled: enabled }
            })
            .then((response) => {
              console.log('US', response)
              if (response !== null) {
                this.$swal({
                  icon: 'success',
                  title: enabled ? 'Enabled!' : 'Disabled!',
                  text: enabled
                    ? 'User has been enabled!'
                    : 'User has been disabled.',
                  customClass: {
                    confirmButton: 'btn btn-success'
                  }
                })
                this.refetchData()
              }
            })
        }
      })
    }

    let scCourtOptions = []
    let courtOptions = []
    let districtOptions = []
    let tribunalOptions = []

    let ll = JSON.parse(localStorage.getItem(defaultConfig.allCourtsKeyName))
    if (ll) {
      scCourtOptions = ll.supremeCourts.map(function (key) {
        return {
          value: key._id,
          label: key.title,
          cslug: key.cli_slug,
          unique_id: key.unique_id
        }
      })
      courtOptions = ll.highCourts.map(function (key) {
        return {
          value: key._id,
          label: key.title,
          cslug: key.cli_slug,
          unique_id: key.unique_id
        }
      })
      districtOptions = ll.districtCourts.map(function (key) {
        return {
          value: key._id,
          label: key.title,
          cslug: key.cli_slug,
          unique_id: key.unique_id
        }
      })
      tribunalOptions = ll.tribunalsCourts.map(function (key) {
        return {
          value: key._id,
          label: key.title,
          cslug: key.cli_slug,
          unique_id: key.unique_id
        }
      })
    }

    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
      userData,
      scCourtOptions,
      courtOptions,
      districtOptions,
      tribunalOptions,
      isUpdateCourtSidebarActive,
      isUpdateUserSidebarActive,
      refetchData,
      changeUserStatus
    }
  }
}
</script>

<style></style>
