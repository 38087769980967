<template>
  <!-- Table Container Card -->
  <div>
    <!-- Table Top -->
    <b-row class="m-1">
      <!-- Per Page -->
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <h5>Matters List</h5>
      </b-col>

      <!-- Search -->
      <b-col cols="12" md="6">
        <b-row class="align-items-center justify-content-end">
          <b-col cols="6">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
              type="search"
              debounce="500"
            />
          </b-col>
          <b-col cols="6">
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="invoice-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-overlay
      :show="$store.state.matterStore.mattersLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <b-table
        ref="refInvoiceListTable"
        :items="fetchInvoices"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>

        <template #cell(title)="data">
          <router-link
              :to="{
                name: 'users-view',
                params: { id: data.item._id }
              }"
              target="_blank"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </router-link>
          <div
            class="mb-1 text-primary cursor-pointer"
            v-b-tooltip.hover
            title="View Matter"
            @click="
              $router.push({
                name: 'user-matter-preview',
                params: { id: data.item._id }
              })
            "
          >
            <h6 class="text-primary">
              {{ data.item.title }}
            </h6>
            <div v-if="Object.keys(data.item).includes('hearing_dates')">
              <small
                class="m-0 text-success font-weight-bold"
                v-if="data.item.hearing_dates !== null"
              >
                {{ getNextDate({ dates: data.item.hearing_dates }) }}
              </small>
              <small class="m-0" v-else>--</small>
            </div>
            <small class="m-0" v-else>--</small>
          </div>
        </template>

        <!-- Column: Unique Id -->
        <template #cell(unique_id)="data">
          <b-col>
            <h6 class="text-nowrap font-weight-bold" v-if="data.item.unique_id">
              {{
                getUniqueId(data.item)
              }}
            </h6>
            <div v-if="Object.keys(data.item).includes('ctitle')">
              <small class="text-secondary font-italic">{{
                data.item.ctitle
              }}</small>
            </div>
          </b-col>
        </template>

        <!-- Column: Balance -->
        <template #cell(case_status)="data">
          <template v-if="data.item.case_status.toLowerCase() === 'pending'">
            <b-badge pill variant="light-primary">
              {{ data.item.case_status }}
            </b-badge>
          </template>
          <template v-else>
            <b-badge pill variant="light-danger">
              {{ data.item.case_status }}
            </b-badge>
          </template>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-badge
              variant="primary"
              pill
              v-b-tooltip.hover
              title="View Matter"
              class="badge-round cursor-pointer mr-1"
              @click="
                $router.push({
                  name: 'user-matter-preview',
                  params: { id: data.item._id }
                })
              "
            >
              <feather-icon
                :id="`matter-${data.item._id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mr-25"
              />
              <span>View</span>
            </b-badge>

            <!-- <b-badge
              variant="danger"
              pill
              v-b-tooltip.hover
              title="Delete Matter"
              class="badge-round cursor-pointer"
              @click="deleteAlert(data.item._id)"
            >
              <feather-icon
                :id="`matter-${data.item._id}-preview-icon`"
                icon="TrashIcon"
                size="16"
              />
            </b-badge> -->
          </div>
        </template>
      </b-table>
    </b-overlay>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import moment from 'moment'

// import store from '@/store'
import useMatterList from './useMatterList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BOverlay,

    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  beforeMount() {},
  methods: {
    getNextDate({ dates }) {
      var date = '--'
      var dd = []
      const filteredDates = dates.filter(
        (date) => moment(date) >= moment().startOf('day')
      )
      for (let i = 0; i < filteredDates.length; i++) {
        dd.push(moment(filteredDates[i]).format('Do, MMMM YYYY'))
      }
      date = dd.join(' || ')
      return date
    },
    // delete alert
    deleteAlert(_id) {
      this.$swal({
        title: 'Warning',
        icon: 'warning',
        text: 'You really want to delete this matter',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('matterStore/deleteMatter', {
              ctx: this,
              _id: _id
            })
            .then((result) => {
              this.refetchData()
            })
        }
      })
    }
  },
  setup(props) {
    const statusOptions = ['Pending', 'Closed', 'All']

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData
    } = useMatterList({ id: props.id })

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      statusOptions,

      refetchData,
      formatDate,

      avatarText
    }
  }
}
</script>
