import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    ticketLoading: false
  },
  getters: {},
  mutations: {
    changeTicketLoading(state, val) {
      state.ticketLoading = val
    }
  },
  actions: {
    fetchTickets({ commit }, { ctx, queryParams }) {
      commit('changeTicketLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getTicketsEndPoint, { params: queryParams })
          .then((response) => {
            commit('changeTicketLoading', false)
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeTicketLoading', false)
            reject(error)
          })
      })
    },
    updateTicket({ commit }, { ctx, payload }) {
      commit('changeTicketLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.updateTicketEndPoint, payload)
          .then((response) => {
            console.log(response.data.success)
            commit('changeTicketLoading', false)
            return resolve(response.data.success)
          })
          .catch((error) => {
            commit('changeTicketLoading', false)
            return reject(error)
          })
      })
    },

    fetchInquiries({ commit }, { ctx, queryParams }) {
      commit('changeTicketLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getInquiriesEndPoint, { params: queryParams })
          .then((response) => {
            commit('changeTicketLoading', false)
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeTicketLoading', false)
            reject(error)
          })
      })
    },
    updateInquiry({ commit }, { ctx, payload }) {
      commit('changeTicketLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.updateInquiryEndPoint, payload)
          .then((response) => {
            console.log(response.data.success)
            commit('changeTicketLoading', false)
            return resolve(response.data.success)
          })
          .catch((error) => {
            commit('changeTicketLoading', false)
            return reject(error)
          })
      })
    }
  }
}
