export default [
    {
      header: 'Settings',
    },
    {
      title: 'Account Setting',
      route: 'account-setting',
      icon: 'UserIcon',
    }
  ]
  