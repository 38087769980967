<template>
  <b-card title="Assign Plan">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        class="py-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="6">
            <!-- Plan -->
            <validation-provider
              #default="validationContext"
              name="Subscription Plan"
              rules="required"
            >
              <b-form-group
                label="Subscription Plan"
                label-for="plan"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="planData.plan_reference_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="$store.state.planStore.plansUpgradeList"
                  :reduce="(val) => val.reference_id"
                  :clearable="false"
                  label="name"
                  input-id="plan"
                >
                  <template slot="option" slot-scope="option">
                    <span></span>
                    {{ option.name }} - ({{ option.period_days }} days) (₹{{
                      option.price
                    }})
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ option.name }} - ({{ option.period_days }} days) (₹{{
                      option.price
                    }})
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Mode -->
            <validation-provider
              #default="validationContext"
              name="Payment Mode"
              rules="required"
            >
              <b-form-group
                label="Payment Plan"
                label-for="mode"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="planData.payment_mode"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="[
                    'Cash',
                    'UPI',
                    'Net Banking',
                    'Payment Gateway',
                    'Cheque'
                  ]"
                  :reduce="(val) => val"
                  :clearable="false"
                  label="name"
                  input-id="mode"
                >
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 mt-1 px-2"
              type="submit"
            >
              Add Now
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BMedia,
  BBadge,
  BRow,
  BCol,
  BButton,
  BFormInvalidFeedback,
  BForm,
  BFormGroup
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import store from '@/store'
import defaultConfig from '@/configs/defaultConfig'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BRow,
    BCol,
    BBadge,
    BButton,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BForm,
    BFormGroup
  },
  directives: {
    Ripple
  },
  props: {
    uData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      user: this.uData,
      required
    }
  },
  setup(props, context) {

    const blankData = {
      plan_reference_id: null,
      payment_mode: null,
      user_id: props.uData._id
    }

    const planData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetplanData = () => {
      planData.value = JSON.parse(JSON.stringify(blankData))
    }

    function onSubmit() {
      this.$swal({
        title: 'Are you sure?',
        text: 'You really want to assign a plan to this user',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, assign it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          store
            .dispatch('userStore/applyPlan', { data: planData.value })
            .then((response) => {
              if (response != null) {
                context.emit('refetch-data')
              }
            })
        }
      })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetplanData)

    return {
      planData,
      onSubmit,
      scCourtOptions,
      courtOptions,
      refFormObserver,
      getValidationState,
      resetForm
    }
  }
}
</script>

<style></style>
