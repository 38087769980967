var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-1"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('h5',[_vm._v("Matters List")])]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-row',{staticClass:"align-items-center justify-content-end"},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search...","type":"search","debounce":"500"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"invoice-filter-select",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"placeholder":"Select Status"},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(" "+_vm._s(label)+" ")])]}}]),model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.$store.state.matterStore.mattersLoading,"rounded":"sm","variant":"transparent","opacity":"0.5","blur":"2px"}},[_c('b-table',{ref:"refInvoiceListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchInvoices,"responsive":"","fields":_vm.tableColumns,"primary-key":"_id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"head(invoiceStatus)",fn:function(){return [_c('feather-icon',{staticClass:"mx-auto",attrs:{"icon":"TrendingUpIcon"}})]},proxy:true},{key:"cell(title)",fn:function(data){return [_c('router-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
              name: 'users-view',
              params: { id: data.item._id }
            },"target":"_blank"}},[_vm._v(" "+_vm._s(data.item.fullName)+" ")]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mb-1 text-primary cursor-pointer",attrs:{"title":"View Matter"},on:{"click":function($event){return _vm.$router.push({
              name: 'user-matter-preview',
              params: { id: data.item._id }
            })}}},[_c('h6',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(data.item.title)+" ")]),(Object.keys(data.item).includes('hearing_dates'))?_c('div',[(data.item.hearing_dates !== null)?_c('small',{staticClass:"m-0 text-success font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getNextDate({ dates: data.item.hearing_dates }))+" ")]):_c('small',{staticClass:"m-0"},[_vm._v("--")])]):_c('small',{staticClass:"m-0"},[_vm._v("--")])])]}},{key:"cell(unique_id)",fn:function(data){return [_c('b-col',[(data.item.unique_id)?_c('h6',{staticClass:"text-nowrap font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getUniqueId(data.item))+" ")]):_vm._e(),(Object.keys(data.item).includes('ctitle'))?_c('div',[_c('small',{staticClass:"text-secondary font-italic"},[_vm._v(_vm._s(data.item.ctitle))])]):_vm._e()])]}},{key:"cell(case_status)",fn:function(data){return [(data.item.case_status.toLowerCase() === 'pending')?[_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(data.item.case_status)+" ")])]:[_c('b-badge',{attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" "+_vm._s(data.item.case_status)+" ")])]]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"badge-round cursor-pointer mr-1",attrs:{"variant":"primary","pill":"","title":"View Matter"},on:{"click":function($event){return _vm.$router.push({
                name: 'user-matter-preview',
                params: { id: data.item._id }
              })}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"id":("matter-" + (data.item._id) + "-preview-icon"),"icon":"EyeIcon","size":"16"}}),_c('span',[_vm._v("View")])],1)],1)]}}])})],1),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalInvoices,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }