var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('basic-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}),_c('tribunals-status',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'linked_matters'
      )
    )?_c('div',[(_vm.$store.state.matterStore.currentmatter.linked_matters)?_c('linked-matters',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}):_vm._e()],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'petitioner_advocates'
      ) ||
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'respondent_advocates'
      )
    )?_c('div',[_c('petitioner-respondents',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'reference_case_details'
      )
    )?_c('div',[_c('reference-case-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'document_details'
      )
    )?_c('div',[_c('document-filing-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'case_proceeding'
      )
    )?_c('div',[_c('case-proceeding',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }