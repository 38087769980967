<template>
  <li class="nav-item nav-search">
    <!-- Icon -->
    <!-- <a
      class="nav-link nav-link-search"
      
    >
      <feather-icon icon="SearchIcon" size="21" />
    </a> -->

    <!-- Input -->
    <!-- <div class="search-input" :class="{ open: showSearchBar }"> -->
      <!-- <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="Find Your Matters"
        autofocus
        autocomplete="off"
        @keyup.up="increaseIndex(false)"
        @keyup.down="increaseIndex"
        @keyup.esc="
          showSearchBar = false
          resetsearchQuery()
        "
        @keyup.enter="suggestionSelected"
      />
      <div
        class="search-input-close"
        @click="
          showSearchBar = false
          resetsearchQuery()
        "
      >
        <feather-icon icon="XIcon" />
      </div> -->

      <!-- Suggestions List -->
      <!-- <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="search-list search-list-main scroll-area overflow-hidden"
        :class="{ show: searchQuery }"
        tagname="ul"
      >
        <div v-if="searchQuery">
          <matter-search-list :type="'all'" :query="searchQuery" />
        </div>
      </vue-perfect-scrollbar> -->
    <!-- </div> -->
  </li>
</template>

<script>
import { BFormInput, BLink, BImg, BAvatar, BButton } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import store from '@/store'
// import MatterSearchList from '@/views/dashboard/searchFilter/MatterSearchList.vue'

export default {
  components: {
    BFormInput,
    BLink,
    BImg,
    BAvatar,
    BButton,
    VuePerfectScrollbar,
    // MatterSearchList
  },
  setup() {
    const showSearchBar = ref(false)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60
    }

    const searchQuery = ref('')

    const resetsearchQuery = () => {
      searchQuery.value = ''
    }

    watch(searchQuery, (val) => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    })

    return {
      showSearchBar,
      perfectScrollbarSettings,
      searchQuery,
      resetsearchQuery
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

/* .app-auto-suggest {
    position: relative;
  }
  
  .auto-suggest-suggestions-list {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    border-radius: 6px;
    position: absolute;
    top: calc(100% + 1rem);
    background-color: white;
    width: 100%;
  } */

.suggestion-group-title {
  font-weight: 500;
  padding: 0.75rem 1rem 0.25rem;
}

.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
