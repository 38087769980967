<template>
  <b-overlay
    :show="$store.state.causelistStore.exportCauseListLoading && $store.state.hearingStore.currentExportDate === itemDate"
    rounded="sm"
    variant="transparent"
    opacity="0.5"
    blur="2px"
  >
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="danger"
      v-b-tooltip.hover
      title="Export PDF"
      :disabled="!itemDate"
      class="btn-sm"
      @click="exportCauseList"
    >
      Export PDF
    </b-button>
  </b-overlay>
</template>

<script>
import { BButton, VBTooltip, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'

export default {
  components: {
    BButton,
    BOverlay
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  props: {
    date: {
      type: String,
      default: null
    },
    userId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      itemDate: this.date,
      id: this.userId
    }
  },
  methods: {
    exportCauseList() {
      let date = moment(this.itemDate, "DD-MM-YYYY").format("YYYY-MM-DD")
      this.$store
        .dispatch('causelistStore/exportCauseListByDate', { date: date, user_id: this.id })
        .then((response) => {
          if (response) {
            var link = document.createElement('a')
            link.href = response.url.replace("http://s3.mymunshi.live", "https://app.mymunshi.in/exports")
            link.target = '_blank'
            link.download = `causelist_${date}.pdf`
            link.click()
            window.URL.revokeObjectURL(link)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>
