<template>
  <section v-if="data.case_details !== null">
    <h3 class="pb-1">Case Status</h3>
    <b-card no-body class="py-75">
      <!-- First Hearing Date -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">First Hearing</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('first_hearing_date')"
          >
            {{ data.case_details.first_hearing_date }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Next Hearing Date -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Last Hearing</h6>
        </b-col>
        <b-col class="col-7">
          <div v-if="Object.keys(data).includes('listing_history')">
            <div v-if="data.listing_history.length">
              <p
                class="m-0 text-primary"
                v-if="
                  Object.keys(data.listing_history[0]).includes('listing_date')
                "
              >
                {{ data.listing_history[0].listing_date }}
              </p>
              <p class="m-0" v-else>--</p>
            </div>
            <p class="m-0" v-else>--</p>
          </div>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Next Hearing Date -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Next Hearing</h6>
        </b-col>
        <b-col class="col-7">
          <div v-if="Object.keys(data).includes('hearing_dates')">
            <p
              class="m-0 text-success font-weight-bold"
              v-if="data.hearing_dates !== null"
            >
              {{ getNextDate({ dates: data.hearing_dates }) }}
            </p>
            <p class="m-0" v-else>--</p>
          </div>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Judicial Branch -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Judicial Branch</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('judicial_branch')"
          >
            {{ data.case_details.judicial_branch }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Stage of Case -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Stage of Case</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('stage')"
          >
            {{ data.case_details.stage }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Causelist Type -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Causelist Type</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('causelist_type')"
          >
            {{ data.case_details.causelist_type }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Bench Type -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Bench Type</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('bench_type')"
          >
            {{ data.case_details.bench_type }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- Coram -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">Coram</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('coram')"
          >
            {{ data.case_details.coram }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- State -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">State</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('state')"
          >
            {{ data.case_details.state }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
      <hr style="margin: 5px 0" />
      <!-- District -->
      <b-row class="px-1">
        <b-col class="col-5">
          <h6 class="m-0">District</h6>
        </b-col>
        <b-col class="col-7">
          <p
            class="m-0"
            v-if="Object.keys(data.case_details).includes('district')"
          >
            {{ data.case_details.district }}
          </p>
          <p class="m-0" v-else>--</p>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  },
  methods: {
    getNextDate({ dates }) {
      var date = '--'
      var dd = []
      const filteredDates = dates.filter(
        (date) => moment(date) >= moment().startOf('day')
      )
      for (let i = 0; i < filteredDates.length; i++) {
        dd.push(moment(filteredDates[i]).format('Do, MMMM YYYY'))
      }
      date = dd.join(' || ')
      return date
    }
  },
  setup() {
    return {
      formatDate
    }
  }
}
</script>
